export const sweeps = {
    'ipad': {
        name: 'iPad',
        path: '/ipad',
        image: '2020-apple-ipad-air-2.png',
        gateClass: 'h-64 mx-auto mt-5 mb-6',
        sweepClass: 'h-64 m-auto sm:h-full',
        article: 'an'
    },
    'purple-pillow': {
        name: 'Purple Pillow',
        path: '/purple-pillow',
        image: 'purple-pillows.png',
        gateClass: 'h-64 mx-auto',
        sweepClass: 'w-full -my-10 sm:mt-3 sm:mb-0',
        article: 'a'
    },
    'nespresso': {
        name: 'Nespresso Machine',
        path: '/nespresso',
        image: 'Nespresso.png',
        gateClass: 'h-64 mx-auto mt-5 mb-6',
        sweepClass: 'h-64 m-auto sm:h-full',
        article: 'a'
    },
    'dewalt-drill': {
        name: 'Dewalt Drill',
        path: '/dewalt-drill',
        image: 'drill.png',
        gateClass: '',
        sweepClass: '',
        article: 'a'
    },
    'toaster-oven': {
        name: 'Breville Toaster Oven',
        path: '/toaster-oven',
        image: 'oven.png',
        gateClass: 'h-64',
        sweepClass: '',
        article: 'a'
    },
    'la-z-boy': {
        name: 'La-Z-Boy Recliner',
        path: 'la-z-boy',
        image: 'chair.png',
        gateClass: 'h-64 my-4',
        sweepClass: '',
        article: 'a',
    }
  }