import { createRouter, createWebHistory } from 'vue-router'
import Funnel from '@/views/Funnel'
import PrivacyPolicy from '@/views/PrivacyPolicy'
import TermsAndConditions from '@/views/TermsAndConditions'
import DontSell from '@/views/DontSell'
import PrivacyRights from '@/views/PrivacyRights'
import SweepstakesRules from '@/views/SweepstakesRules'
import MasterSweeper from '@/views/MasterSweeper'
import MasterGater from '@/views/MasterGater'

const routes = [{
  path: '/',
  name: 'Home',
  redirect: {
    name: 'Funnel'
  },
}, {
  path: '/master-sweeper/:sweep',
  name: 'MasterSweeper',
  props: true,
  component: MasterSweeper
}, {
  path: '/master-gater/:sweep',
  name: 'MasterGater',
  props: true,
  component: MasterGater
}, {
  path: '/funnel/:step?',
  name: 'Funnel',
  component: Funnel,
  props: true,
}, {
  path: '/funnel/:step/:token',
  name: 'Complete',
  component: Funnel,
  props: true,
}, {
  path: '/privacy-policy',
  name: 'PrivacyPolicy',
  component: PrivacyPolicy,
  props: true,
}, {
  path: '/terms-and-conditions',
  name: 'TermsAndConditions',
  component: TermsAndConditions,
  props: true,
}, {
  path: '/ccpa-do-not-sell-my-information',
  name: 'DontSell',
  component: DontSell,
  props: true,
}, {
  path: '/ccpa-your-california-privacy-rights',
  name: 'PrivacyRights',
  component: PrivacyRights,
  props: true,
}, {
  path: '/sweepstakes-rules',
  name: 'SweepstakesRules',
  component: SweepstakesRules,
  props: true,
}
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router