<template>
  <div id="step17">
    <div class="flex flex-wrap justify-center mb-4">
      <img class="w-24 h-24" src="../../assets/judge.png" alt="We have more questions" />
    </div>
    
    <h1 class="block text-2xl text-center font-extrabold tracking-tight text-gray-900 sm:text-2xl">
      Survey Time!
    </h1>

    <p class="mt-2 mb-4 text-center text-gray-500">
      Let’s see which surveys are for you!
    </p>

    <form>
      <input id="leadid_token" name="universal_leadid" type="hidden" />

      <div class="mt-4 space-y-3">
        <SwitchGroup as="div" class="flex items-center justify-between">
          <span class="flex-grow flex flex-col mr-2">
            <SwitchLabel as="span" class="text-sm font-medium text-gray-900" passive>
              Class Action
            </SwitchLabel>
            <SwitchDescription as="span" class="text-sm text-gray-500">Have you been affected by any of the following items listed below?</SwitchDescription>
          </span>

          <Switch v-model="classAction" :class="[classAction ? 'bg-indigo-600' : 'bg-gray-200', 'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500']">
            <span class="sr-only">Use setting</span>
            <span :class="[classAction ? 'translate-x-5' : 'translate-x-0', 'pointer-events-none relative inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200']">
              <span :class="[classAction ? 'opacity-0 ease-out duration-100' : 'opacity-100 ease-in duration-200', 'absolute inset-0 h-full w-full flex items-center justify-center transition-opacity']" aria-hidden="true">
                <svg class="h-3 w-3 text-gray-400" fill="none" viewBox="0 0 12 12">
                  <path d="M4 8l2-2m0 0l2-2M6 6L4 4m2 2l2 2" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
              </span>
              <span :class="[classAction ? 'opacity-100 ease-in duration-200' : 'opacity-0 ease-out duration-100', 'absolute inset-0 h-full w-full flex items-center justify-center transition-opacity']" aria-hidden="true">
                <svg class="h-3 w-3 text-indigo-600" fill="currentColor" viewBox="0 0 12 12">
                  <path d="M3.707 5.293a1 1 0 00-1.414 1.414l1.414-1.414zM5 8l-.707.707a1 1 0 001.414 0L5 8zm4.707-3.293a1 1 0 00-1.414-1.414l1.414 1.414zm-7.414 2l2 2 1.414-1.414-2-2-1.414 1.414zm3.414 2l4-4-1.414-1.414-4 4 1.414 1.414z" />
                </svg>
              </span>
            </span>
          </Switch>
        </SwitchGroup>
        
        <p class="text-xs text-gray-500">Hernia Mesh, Zantac, Talcum Powder, Car Injury, Bankruptcy, Personal Injury, Clergy Abuse.</p>

        <template v-if="classAction">
          <SwitchGroup as="div" class="flex items-center justify-between">
            <span class="flex-grow flex flex-col mr-2">
              <SwitchLabel as="span" class="text-sm font-medium text-gray-900" passive>
                Hernia Mesh
              </SwitchLabel>
              <SwitchDescription as="span" class="text-sm text-gray-500">
                Have you undergone a hernia mesh implant and experienced serious complications?
              </SwitchDescription>
            </span>

            <Switch v-model="herniaMesh" :class="[herniaMesh ? 'bg-indigo-600' : 'bg-gray-200', 'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500']">
              <span class="sr-only">Use setting</span>
              <span :class="[herniaMesh ? 'translate-x-5' : 'translate-x-0', 'pointer-events-none relative inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200']">
                <span :class="[herniaMesh ? 'opacity-0 ease-out duration-100' : 'opacity-100 ease-in duration-200', 'absolute inset-0 h-full w-full flex items-center justify-center transition-opacity']" aria-hidden="true">
                  <svg class="h-3 w-3 text-gray-400" fill="none" viewBox="0 0 12 12">
                    <path d="M4 8l2-2m0 0l2-2M6 6L4 4m2 2l2 2" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                  </svg>
                </span>
                <span :class="[herniaMesh ? 'opacity-100 ease-in duration-200' : 'opacity-0 ease-out duration-100', 'absolute inset-0 h-full w-full flex items-center justify-center transition-opacity']" aria-hidden="true">
                  <svg class="h-3 w-3 text-indigo-600" fill="currentColor" viewBox="0 0 12 12">
                    <path d="M3.707 5.293a1 1 0 00-1.414 1.414l1.414-1.414zM5 8l-.707.707a1 1 0 001.414 0L5 8zm4.707-3.293a1 1 0 00-1.414-1.414l1.414 1.414zm-7.414 2l2 2 1.414-1.414-2-2-1.414 1.414zm3.414 2l4-4-1.414-1.414-4 4 1.414 1.414z" />
                  </svg>
                </span>
              </span>
            </Switch>
          </SwitchGroup>
          
          <SwitchGroup as="div" class="flex items-center justify-between">
            <span class="flex-grow flex flex-col mr-2">
              <SwitchLabel as="span" class="text-sm font-medium text-gray-900" passive>
                Zantac
              </SwitchLabel>
              <SwitchDescription as="span" class="text-sm text-gray-500">
                Have you suffered from personal injury or other damages from using Zantac?
              </SwitchDescription>
            </span>

            <Switch v-model="zantac" :class="[zantac ? 'bg-indigo-600' : 'bg-gray-200', 'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500']">
              <span class="sr-only">Use setting</span>
              <span :class="[zantac ? 'translate-x-5' : 'translate-x-0', 'pointer-events-none relative inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200']">
                <span :class="[zantac ? 'opacity-0 ease-out duration-100' : 'opacity-100 ease-in duration-200', 'absolute inset-0 h-full w-full flex items-center justify-center transition-opacity']" aria-hidden="true">
                  <svg class="h-3 w-3 text-gray-400" fill="none" viewBox="0 0 12 12">
                    <path d="M4 8l2-2m0 0l2-2M6 6L4 4m2 2l2 2" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                  </svg>
                </span>
                <span :class="[zantac ? 'opacity-100 ease-in duration-200' : 'opacity-0 ease-out duration-100', 'absolute inset-0 h-full w-full flex items-center justify-center transition-opacity']" aria-hidden="true">
                  <svg class="h-3 w-3 text-indigo-600" fill="currentColor" viewBox="0 0 12 12">
                    <path d="M3.707 5.293a1 1 0 00-1.414 1.414l1.414-1.414zM5 8l-.707.707a1 1 0 001.414 0L5 8zm4.707-3.293a1 1 0 00-1.414-1.414l1.414 1.414zm-7.414 2l2 2 1.414-1.414-2-2-1.414 1.414zm3.414 2l4-4-1.414-1.414-4 4 1.414 1.414z" />
                  </svg>
                </span>
              </span>
            </Switch>
          </SwitchGroup>
          
          <SwitchGroup as="div" class="flex items-center justify-between">
            <span class="flex-grow flex flex-col mr-2">
              <SwitchLabel as="span" class="text-sm font-medium text-gray-900" passive>
                Talcum Powder
              </SwitchLabel>
              <SwitchDescription as="span" class="text-sm text-gray-500">
                Have you used Johnson & Johnson baby powder in the past 15 years? If so, have you been diagnosed with Ovarian or Invasive Fallopian Tube Cancer?
              </SwitchDescription>
            </span>

            <Switch v-model="talcumPowder" :class="[talcumPowder ? 'bg-indigo-600' : 'bg-gray-200', 'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500']">
              <span class="sr-only">Use setting</span>
              <span :class="[talcumPowder ? 'translate-x-5' : 'translate-x-0', 'pointer-events-none relative inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200']">
                <span :class="[talcumPowder ? 'opacity-0 ease-out duration-100' : 'opacity-100 ease-in duration-200', 'absolute inset-0 h-full w-full flex items-center justify-center transition-opacity']" aria-hidden="true">
                  <svg class="h-3 w-3 text-gray-400" fill="none" viewBox="0 0 12 12">
                    <path d="M4 8l2-2m0 0l2-2M6 6L4 4m2 2l2 2" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                  </svg>
                </span>
                <span :class="[talcumPowder ? 'opacity-100 ease-in duration-200' : 'opacity-0 ease-out duration-100', 'absolute inset-0 h-full w-full flex items-center justify-center transition-opacity']" aria-hidden="true">
                  <svg class="h-3 w-3 text-indigo-600" fill="currentColor" viewBox="0 0 12 12">
                    <path d="M3.707 5.293a1 1 0 00-1.414 1.414l1.414-1.414zM5 8l-.707.707a1 1 0 001.414 0L5 8zm4.707-3.293a1 1 0 00-1.414-1.414l1.414 1.414zm-7.414 2l2 2 1.414-1.414-2-2-1.414 1.414zm3.414 2l4-4-1.414-1.414-4 4 1.414 1.414z" />
                  </svg>
                </span>
              </span>
            </Switch>
          </SwitchGroup>

          <div v-show="talcumPowder">
            <label for="talcum_powder_explanation" class="block text-sm font-medium text-gray-700">
              Tell us about your talcum powder case
            </label>

            <div class="mt-1">
              <input v-model="talcumPowderExpl" id="talcum_powder_explanation" type="text" required class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" />
            </div>
          </div>
          
          <SwitchGroup as="div" class="flex items-center justify-between">
            <span class="flex-grow flex flex-col mr-2">
              <SwitchLabel as="span" class="text-sm font-medium text-gray-900" passive>
                Car Injury
              </SwitchLabel>
              <SwitchDescription as="span" class="text-sm text-gray-500">
                Have you recently been injured in a car accident?
              </SwitchDescription>
            </span>

            <Switch v-model="carInjury" :class="[carInjury ? 'bg-indigo-600' : 'bg-gray-200', 'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500']">
              <span class="sr-only">Use setting</span>
              <span :class="[carInjury ? 'translate-x-5' : 'translate-x-0', 'pointer-events-none relative inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200']">
                <span :class="[carInjury ? 'opacity-0 ease-out duration-100' : 'opacity-100 ease-in duration-200', 'absolute inset-0 h-full w-full flex items-center justify-center transition-opacity']" aria-hidden="true">
                  <svg class="h-3 w-3 text-gray-400" fill="none" viewBox="0 0 12 12">
                    <path d="M4 8l2-2m0 0l2-2M6 6L4 4m2 2l2 2" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                  </svg>
                </span>
                <span :class="[carInjury ? 'opacity-100 ease-in duration-200' : 'opacity-0 ease-out duration-100', 'absolute inset-0 h-full w-full flex items-center justify-center transition-opacity']" aria-hidden="true">
                  <svg class="h-3 w-3 text-indigo-600" fill="currentColor" viewBox="0 0 12 12">
                    <path d="M3.707 5.293a1 1 0 00-1.414 1.414l1.414-1.414zM5 8l-.707.707a1 1 0 001.414 0L5 8zm4.707-3.293a1 1 0 00-1.414-1.414l1.414 1.414zm-7.414 2l2 2 1.414-1.414-2-2-1.414 1.414zm3.414 2l4-4-1.414-1.414-4 4 1.414 1.414z" />
                  </svg>
                </span>
              </span>
            </Switch>
          </SwitchGroup>

          <div v-show="carInjury">
            <label for="car_injury_explanation" class="block text-sm font-medium text-gray-700">
              Tell us about your car injury
            </label>

            <div class="mt-1">
              <input v-model="carInjuryExpl" id="car_injury_explanation" type="text" autocomplete="car_injury_explanation" required class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" />
            </div>
          </div>
          
          <SwitchGroup as="div" class="flex items-center justify-between">
            <span class="flex-grow flex flex-col mr-2">
              <SwitchLabel as="span" class="text-sm font-medium text-gray-900" passive>
                Personal Bankruptcy
              </SwitchLabel>
              <SwitchDescription as="span" class="text-sm text-gray-500">
                Have you filed for bankruptcy in the last 10 years?
              </SwitchDescription>
            </span>

            <Switch v-model="bankruptcy" :class="[bankruptcy ? 'bg-indigo-600' : 'bg-gray-200', 'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500']">
              <span class="sr-only">Use setting</span>
              <span :class="[bankruptcy ? 'translate-x-5' : 'translate-x-0', 'pointer-events-none relative inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200']">
                <span :class="[bankruptcy ? 'opacity-0 ease-out duration-100' : 'opacity-100 ease-in duration-200', 'absolute inset-0 h-full w-full flex items-center justify-center transition-opacity']" aria-hidden="true">
                  <svg class="h-3 w-3 text-gray-400" fill="none" viewBox="0 0 12 12">
                    <path d="M4 8l2-2m0 0l2-2M6 6L4 4m2 2l2 2" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                  </svg>
                </span>
                <span :class="[bankruptcy ? 'opacity-100 ease-in duration-200' : 'opacity-0 ease-out duration-100', 'absolute inset-0 h-full w-full flex items-center justify-center transition-opacity']" aria-hidden="true">
                  <svg class="h-3 w-3 text-indigo-600" fill="currentColor" viewBox="0 0 12 12">
                    <path d="M3.707 5.293a1 1 0 00-1.414 1.414l1.414-1.414zM5 8l-.707.707a1 1 0 001.414 0L5 8zm4.707-3.293a1 1 0 00-1.414-1.414l1.414 1.414zm-7.414 2l2 2 1.414-1.414-2-2-1.414 1.414zm3.414 2l4-4-1.414-1.414-4 4 1.414 1.414z" />
                  </svg>
                </span>
              </span>
            </Switch>
          </SwitchGroup>

          <div v-show="bankruptcy">
            <label for="bankruptcy_explanation" class="block text-sm font-medium text-gray-700">
              Tell us about your bankruptcy
            </label>

            <div class="mt-1">
              <input v-model="bankruptcyExpl" id="bankruptcy_explanation" type="text" autocomplete="bankruptcy_explanation" required class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" />
            </div>
          </div>
          
          <SwitchGroup as="div" class="flex items-center justify-between">
            <span class="flex-grow flex flex-col mr-2">
              <SwitchLabel as="span" class="text-sm font-medium text-gray-900" passive>
                Personal Injury
              </SwitchLabel>
              <SwitchDescription as="span" class="text-sm text-gray-500">
                Has an individual or a company wronged you or caused you mental, emotional distress?
              </SwitchDescription>
            </span>

            <Switch v-model="personalInjury" :class="[personalInjury ? 'bg-indigo-600' : 'bg-gray-200', 'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500']">
              <span class="sr-only">Use setting</span>
              <span :class="[personalInjury ? 'translate-x-5' : 'translate-x-0', 'pointer-events-none relative inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200']">
                <span :class="[personalInjury ? 'opacity-0 ease-out duration-100' : 'opacity-100 ease-in duration-200', 'absolute inset-0 h-full w-full flex items-center justify-center transition-opacity']" aria-hidden="true">
                  <svg class="h-3 w-3 text-gray-400" fill="none" viewBox="0 0 12 12">
                    <path d="M4 8l2-2m0 0l2-2M6 6L4 4m2 2l2 2" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                  </svg>
                </span>
                <span :class="[personalInjury ? 'opacity-100 ease-in duration-200' : 'opacity-0 ease-out duration-100', 'absolute inset-0 h-full w-full flex items-center justify-center transition-opacity']" aria-hidden="true">
                  <svg class="h-3 w-3 text-indigo-600" fill="currentColor" viewBox="0 0 12 12">
                    <path d="M3.707 5.293a1 1 0 00-1.414 1.414l1.414-1.414zM5 8l-.707.707a1 1 0 001.414 0L5 8zm4.707-3.293a1 1 0 00-1.414-1.414l1.414 1.414zm-7.414 2l2 2 1.414-1.414-2-2-1.414 1.414zm3.414 2l4-4-1.414-1.414-4 4 1.414 1.414z" />
                  </svg>
                </span>
              </span>
            </Switch>
          </SwitchGroup>

          <div v-show="personalInjury">
            <label for="personal_injury_explanation" class="block text-sm font-medium text-gray-700">
              Tell us about your personal injury
            </label>

            <div class="mt-1">
              <input v-model="personalInjuryExpl" id="personal_injury_explanation" type="text" required class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" />
            </div>
          </div>
          
          <SwitchGroup as="div" class="flex items-center justify-between">
            <span class="flex-grow flex flex-col mr-2">
              <SwitchLabel as="span" class="text-sm font-medium text-gray-900" passive>
                Clergy Abuse
              </SwitchLabel>
              <SwitchDescription as="span" class="text-sm text-gray-500">
                Have you been abused as a minor by a Catholic Church official?
              </SwitchDescription>
            </span>

            <Switch v-model="clergyAbuse" :class="[clergyAbuse ? 'bg-indigo-600' : 'bg-gray-200', 'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500']">
              <span class="sr-only">Use setting</span>
              <span :class="[clergyAbuse ? 'translate-x-5' : 'translate-x-0', 'pointer-events-none relative inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200']">
                <span :class="[clergyAbuse ? 'opacity-0 ease-out duration-100' : 'opacity-100 ease-in duration-200', 'absolute inset-0 h-full w-full flex items-center justify-center transition-opacity']" aria-hidden="true">
                  <svg class="h-3 w-3 text-gray-400" fill="none" viewBox="0 0 12 12">
                    <path d="M4 8l2-2m0 0l2-2M6 6L4 4m2 2l2 2" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                  </svg>
                </span>
                <span :class="[clergyAbuse ? 'opacity-100 ease-in duration-200' : 'opacity-0 ease-out duration-100', 'absolute inset-0 h-full w-full flex items-center justify-center transition-opacity']" aria-hidden="true">
                  <svg class="h-3 w-3 text-indigo-600" fill="currentColor" viewBox="0 0 12 12">
                    <path d="M3.707 5.293a1 1 0 00-1.414 1.414l1.414-1.414zM5 8l-.707.707a1 1 0 001.414 0L5 8zm4.707-3.293a1 1 0 00-1.414-1.414l1.414 1.414zm-7.414 2l2 2 1.414-1.414-2-2-1.414 1.414zm3.414 2l4-4-1.414-1.414-4 4 1.414 1.414z" />
                  </svg>
                </span>
              </span>
            </Switch>
          </SwitchGroup>
        </template>
      </div>

      <div class="mt-6">
        <button @click.prevent="next" type="submit" :disabled="! canProgress" class="disabled:opacity-50 disabled:cursor-not-allowed w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
          Continue
        </button>
      </div>
    </form>
  </div>
</template>

<script>
  import Member from '@/mixins/member'
  import { Switch, SwitchDescription, SwitchGroup, SwitchLabel } from '@headlessui/vue'

  export default {
    mixins: [ Member ],
    components: {
      Switch,
      SwitchDescription,
      SwitchGroup,
      SwitchLabel,
    },
    data() {
      return {
        classAction: false,
        herniaMesh: false,
        zantac: false,
        talcumPowder: false,
        talcumPowderExpl: '',
        carInjury: false,
        carInjuryExpl: '',
        bankruptcy: false,
        bankruptcyExpl: '',
        personalInjury: false,
        personalInjuryExpl: '',
        clergyAbuse: false,
      }
    },
    computed: {
      canProgress() {
        return ! this.classAction     ||
                 this.herniaMesh      ||
                 this.zantac          ||
                 (this.talcumPowder   && this.talcumPowderExpl)   ||
                 (this.carInjury      && this.carInjuryExpl)      ||
                 (this.bankruptcy     && this.bankruptcyExpl)     ||
                 (this.personalInjury && this.personalInjuryExpl) ||
                 this.clergyAbuse
      }
    },
    methods: {
      next() {
        this.setClickOffers({
          class_action: this.classAction,
          hernia_mesh: this.herniaMesh,
          zantac: this.zantac,
          talcum_powder: this.talcumPowder,
          talcum_powder_expl: this.talcumPowderExpl,
          car_injury: this.carInjury,
          car_injury_expl: this.carInjuryExpl,
          bankruptcy: this.bankruptcy,
          bankruptcy_expl: this.bankruptcyExpl,
          personal_injury: this.personalInjury,
          personal_injury_expl: this.personalInjuryExpl,
          clergy_abuse: this.clergyAbuse,
        })

        if (this.member) {
          this.updateMember({
            id: this.member.id
          })
        }

        this.$emit('next', 10)
      },
    },
    created() {
      if (this.member) {
        this.classAction = this.member.click_offers.class_action
        this.herniaMesh = this.member.click_offers.hernia_mesh
        this.zantac = this.member.click_offers.zantac
        this.talcumPowder = this.member.click_offers.talcum_powder
        this.talcumPowderExpl = this.member.click_offers.talcum_powder_expl
        this.carInjury = this.member.click_offers.car_injury
        this.carInjuryExpl = this.member.click_offers.car_injury_expl
        this.bankruptcy = this.member.click_offers.bankruptcy
        this.bankruptcyExpl = this.member.click_offers.bankruptcy_expl
        this.personalInjury = this.member.click_offers.personal_injury
        this.personalInjuryExpl = this.member.click_offers.personal_injury_expl
        this.clergyAbuse = this.member.click_offers.clergy_abuse
      }
    }
  }
</script>