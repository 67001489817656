<template>
  <div id="step12">
    <div class="flex flex-wrap justify-center mb-4">
      <img class="w-24 h-20" src="../../assets/suitcase.png" alt="What type of job do you have?" />
    </div>

    <h1 class="block text-2xl text-center font-extrabold tracking-tight text-gray-900 sm:text-2xl px-8">
      What type of job do you have?
    </h1>
    
    <form>
      <input id="leadid_token" name="universal_leadid" type="hidden" />

      <button @click.prevent="next('unemployed')" type="submit" class="mt-4 w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
        I am unemployed
      </button>

      <button @click.prevent="next('part-time')" type="submit" class="mt-4 w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
        I have a part-time job
      </button>

      <button @click.prevent="next('full-time')" type="submit" class="mt-4 w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
        I have a full-time job
      </button>

      <button @click.prevent="next('looking')" type="submit" class="mt-4 w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
        I am actively looking
      </button>
    </form>
  </div>
</template>

<script>
  import Member from '@/mixins/member'

  export default {
    mixins: [ Member ],
    methods: {
      next(answer) {
        this.setProfilingQuestions({
          job_type: answer,
        })
        
        if (this.member) {
          this.updateMember({
            id: this.member.id
          })
        }

        this.$emit('next', 10)
      },
    }
  }
</script>