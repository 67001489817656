<template>
  <div
    class="flex flex-col items-center min-h-screen py-10 bg-gray-100 sm:py-8"
  >
    <div
      class="
        w-full
        p-6
        mt-6
        overflow-hidden
        prose
        bg-white
        shadow-md
        sm:max-w-2xl sm:rounded-lg
      "
    >
      <h1>Notice of Right to Opt Out of “Sale”</h1>

      <p>Notice of Right to Opt Out of “Sale”</p>

      <ul class="list-decimal list-inside">
        <li>Our websites collect information from or about you, including personal information, when you use the website services as described in our Privacy Policy. If you are a registered user of the CrowdUp( the “website” or “websites”) services, we may disclose certain information we collect about you for purposes that may be considered a “sale” under the CCPA. For example, we may disclose information to advertising partners, advertising technology companies, and companies that perform advertising-related services in order to provide you with more relevant advertising tailored to your interests on the website services. We may also disclose to our content programmers information about you, which may help personalize your experience and the content and ads you see on websites as well as other platforms and services, as further described in our Privacy Policy.</li>
        <li>Under the CCPA, you have the right to opt out of the “sale” of your personal information. We provide this right, subject to a verifiable consumer request, to account holders regarding personal information collected by us in connection with your account, including any profiles. To exercise this right, please send us an email to <a class="text-blue-300" href="mailto:dpo@Crowdup.com">dpo@Crowdup.com</a>.</li>
        <li>You may designate an authorized agent to make a request on your behalf. Please click here to make a request as an authorized agent.</li>
        <li>If you are not a registered user of the website services, we have limited information about you and do not “sell” your information as described above. However, you have choices regarding how third parties may collect and use data about you from the website services as described below.</li>
        <li>Please note that this opt-out will apply on a going forward basis with respect to our disclosure of information. Even if you opt out, you will continue to receive ads on our websites, which may not be as tailored to your interests.</li>
      </ul>
      <p>
          In addition to providing you the ability to opt out of the “sale” of your personal information under CCPA as described above, you have additional controls and choices with respect to the collection and use of your information by third parties:
      </p>
      <ul class="list-decimal list-inside">
        <li>Some of the third parties that collect information from or about you on the website services in order to provide more relevant advertising to you participate in the Digital Advertising Alliance (“DAA”) Self-Regulatory Program for Online Behavioral Advertising and/or may be members of the Network Advertising Initiative (“NAI”). To make choices about the use of information from or about you for online behavioral or interest-based advertising on websites (desktop and mobile browsers), please visit the DAA and the NAI.</li>
        <li>To make choices about online behavioral or interest-based advertising within mobile applications, you can download the DAA’s AppChoices application from your mobile device at www.aboutads.info/appchoices or from your device’s app store.</li>
        <li>For more information about these and additional controls and choices available to you, please see Section “Information We Collect Through Automatic Data Collection Technologies” of our Privacy Policy.</li>
      </ul>
            
      <p>
          If you have any questions regarding CrowdUp’s data sharing practices, please feel free to email us at <a class="text-blue-300" href="mailto:dpo@Crowdup.com">dpo@Crowdup.com</a>.
      </p>
    </div>
  </div>
</template>