<template>
  <div>
    <iframe
      style="height: 100%; overflow: hidden"
      class="w-full p-2 bg-white border-unset"
      :src="guruUrl"
    />
  </div>
</template>

<script>
export default {
  props: {
    guruUrl: {
      required: true,
      type: String,
    },
    firstName: {
      required: true,
      type: String,
    },
  },
  name: "ConfirmationPage",
  mounted() {
    this.$nextTick(() => {
      this.new_entry_number = this.getRandomInt(100, 500);
    });
  },
  methods: {
    validationIsTrue() {
      return false;
    },
    nextStep() {},
    getRandomInt(min, max) {
      min = Math.ceil(min);
      max = Math.floor(max);
      return Math.floor(Math.random() * (max - min) + min); //The maximum is exclusive and the minimum is inclusive
    },
  },
  data: () => ({
    first_name: "",
    new_entry_number: "",
  }),
};
</script>