<template>
  <div id="step4">
    <div class="flex flex-wrap justify-center mb-4">
      <img
        class="w-24 h-24"
        src="../../assets/pets.png"
        alt="Do you prefer cats or dogs?"
      />
    </div>

    <h1
      v-html="question"
      class="block px-4 text-2xl font-extrabold tracking-tight text-center text-gray-900 sm:text-2xl"
    ></h1>

    <template v-if="showNext">
      <PollResult :question="question"  />
    </template>
    
    <template v-else>
      <form>
        <input id="leadid_token" name="universal_leadid" type="hidden" />

        <div class="grid grid-cols-2 gap-3 mt-4">
          <div>
            <button
              @click.prevent="next('dog')"
              type="submit"
              class="flex justify-center w-full px-4 py-2 text-sm font-medium text-indigo-700 bg-indigo-100 border border-gray-300 rounded-md shadow-sm hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              Dog
            </button>
          </div>

          <div>
            <button
              @click.prevent="next('cat')"
              type="submit"
              class="flex justify-center w-full px-4 py-2 text-sm font-medium text-white bg-indigo-600 border border-transparent rounded-md shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              Cat
            </button>
          </div>
        </div>
      </form>
    </template>
  </div>
</template>

<script>
import Member from "@/mixins/member";
import PollResult from "@/components/funnel/PollResult";

export default {
  components: { PollResult },
  data() {
    return {
      question: "Are you a dog person or a cat person?",
      showNext: false,
    };
  },
  mixins: [Member],
  methods: {
    next(answer) {
      this.setMiscellaneousQuestions({
        dog_cat: answer,
      });

      if (this.member) {
        this.updateMember({
          id: this.member.id,
        });
      }

      this.showNext = true;

      setTimeout(() => {
        this.$emit("next", 10);
      }, 1000);
    },
  },
};
</script>