export const types = {
  getters: {
    GET_STEPS: 'GET_STEPS',
    GET_CURRENT_COINS: 'GET_CURRENT_COINS',
    GET_CURRENT_INDEX: 'GET_CURRENT_INDEX',
    GET_CURRENT_STEP: 'GET_CURRENT_STEP',
    GET_CURRENT_COMPONENT: 'GET_CURRENT_COMPONENT',
  },

  mutations: {
    SET_CURRENT_INDEX: 'SET_CURRENT_INDEX',
    INCREMENT_CURRENT_COINS: 'INCREMENT_CURRENT_COINS',
    INCREMENT_CURRENT_INDEX: 'INCREMENT_CURRENT_INDEX',
    DECREMENT_CURRENT_INDEX: 'DECREMENT_CURRENT_INDEX',
    COMPLETE_STEP: 'COMPLETE_STEP',
  }
}