<template>
  <div id="step20">
    <h1 class="block text-2xl text-center font-extrabold tracking-tight text-gray-900 sm:text-2xl">
      Why Solar Makes Sense for Homeowners
    </h1>

    <p class="mt-2 mb-4 text-center text-gray-500">
      57% of American homeowners are considering solar in 2021. Are you?
    </p>

    <template v-if="offerIsCompleted">
      <form>
        <input id="leadid_token" name="universal_leadid" type="hidden" />

        <button @click.prevent="next" disabled type="submit" class="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
          Complete <BadgeCheckIcon class="badge h-5 w-5 ml-2" aria-hidden="true" />
        </button>
      </form>

      <p class="mt-2 px-2 text-center text-gray-500 text-xs">
        Click the complete button above to complete this step and continue seeing your offers!
      </p>
    </template>
    
    <template v-else>
      <a :href="externalLink" target="_blank" class="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
        See Offer
      </a>

      <p class="mt-2 px-2 text-center text-gray-500 text-xs">
        After completing the offer, you'll be redirected back to continue seeing your offers!
      </p>
    </template>
  </div>
</template>

<script>
  import Member from '@/mixins/member'
  import { BadgeCheckIcon } from '@heroicons/vue/outline'

  export default {
    mixins: [ Member ],
    components: { BadgeCheckIcon },
    props: {
      token: { 
        type: String, 
        default: null 
      },
    },
    computed: {
      externalLink() {
        return process.env.VUE_APP_GURU_SOLAR.replace(':token', this.member.token)
      },
      offerIsCompleted() {
        return !! this.internalOffers.solar
      },
    },
    methods: {
      next() {
        this.updateMember({ id: this.member.id })
        this.$emit('next', 10)
      },
    },
    created() {
      if (this.token) {
        this.setInternalOffers({
          solar: this.token
        })

        setTimeout(() => {
          this.next()
        }, 2000)
      }
    }
  }
</script>