<template>
  <div id="step24">
    <img class="mx-auto w-3/4 mb-2" src="../../assets/survey-to-cash.png" alt="Survey to Cash" />

    <h1 class="block text-2xl text-center font-extrabold tracking-tight text-gray-900 sm:text-2xl">
      Congrats, you're done!
    </h1>

    <p class="mt-2 mb-4 text-center text-gray-500">
      Your reward will be in your inbox. <br />
      Be sure to tell all your friends!
    </p>
    
    <a href="https://www.facebook.com/sharer/sharer.php?u=https://app.crowdup.io" target="_blank" class="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
      Share Survey
    </a>
  </div>
</template>