<template>
  <div id="step15">
    <div class="flex flex-wrap justify-center mb-4">
      <img class="w-24 h-24" src="../../assets/user.png" alt="Personal Information" />
    </div>

    <h1 class="block text-2xl text-center font-extrabold tracking-tight text-gray-900 sm:text-2xl">
      Personal Information
    </h1>

    <p class="mt-2 mb-4 text-center text-gray-500">
      We need this to distribute your reward and help you save money!
    </p>

    <form @submit.prevent="next">
      <input id="leadid_token" name="universal_leadid" type="hidden" />
      
      <div class="mt-4 space-y-3">
        <div>
          <label for="address" class="block text-sm font-medium text-gray-700">
            Street Address
          </label>

          <div class="mt-1">
            <input v-model="address" id="address" type="text" autocomplete="address" required class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" placeholder="9000 Sunset Blvd" />
          </div>
        </div>

        <div>
          <label for="zip_code" class="block text-sm font-medium text-gray-700">
            Zip Code
          </label>

          <div class="mt-1">
            <input v-model="zipCode" v-maska="'#####'" id="zip_code" type="text" autocomplete="zip_code" required class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" placeholder="90001" />
          </div>
        </div>

        <div>
          <label for="phone" class="block text-sm font-medium text-gray-700">
            Mobile Phone
          </label>

          <div class="mt-1">
            <input v-model="phoneNumber" v-maska="'(###) ###-####'" id="phone" type="tel" autocomplete="phone" required class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" placeholder="(555) 555-1212" />
          </div>
        </div>

        <div>
          <label for="date_of_birth" class="block text-sm font-medium text-gray-700">
            Date of Birth
          </label>

          <div class="mt-1">
            <input v-model="dateOfBirth" v-maska="'##/##/####'" id="date_of_birth" type="text" autocomplete="date_of_birth" required class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" placeholder="mm/dd/yyyy" />
          </div>
        </div>
      </div>

      <div class="mt-6">
        <button :disabled="! formIsValid" type="submit" class="disabled:opacity-50 disabled:cursor-not-allowed w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
          Continue
        </button>
      </div>

      <label class="block mt-6 leading-snug text-xs text-gray-500">
        <input type="hidden" id="leadid_tcpa_disclosure" />

        By submitting this form, I expressly consent to be contacted by a <a href="https://www.acquirecrowd.com/partners/" target="_blank" style="text-decoration: underline">partner</a> in the AcquireCrowd Network with materials for services via direct or electronic mail, phone calls to the phone number provided, text/SMS messages, via automatic/automated dialing system(s), and pre-recorded messages. Consent is not a condition of purchase and may be revoked at any time.
      </label>
    </form>
  </div>
</template>

<script>
  import Member from '@/mixins/member'
  import { mask } from 'maska'

  export default {
    mixins: [ Member ],
    data() {
      return {
        address: '',
        zipCode: '',
        phoneNumber: '',
        dateOfBirth: '',
      }
    },
    computed: {
      unformattedPhone() {
        return mask(this.phoneNumber, '##########')
      },
      addressIsValid() {
        return this.address.length > 0
      },
      zipCodeIsValid() {
        return this.zipCode.length === 5
      },
      phoneNumberIsValid() {
        return this.unformattedPhone.length === 10
      },
      dateOfBirthIsValid() {
        return this.$moment(this.dateOfBirth, 'MM/DD/YYYY', true).isValid()
      },
      formIsValid() {
        return this.addressIsValid     &&
               this.zipCodeIsValid     &&
               this.phoneNumberIsValid &&
               this.dateOfBirthIsValid
      }
    },
    methods: {
      next() {
        this.updateMember({
          id: this.member.id,
          data: {
            address: this.address,
            zip_code: this.zipCode,
            phone_number: this.unformattedPhone,
            date_of_birth: this.dateOfBirth,
          }
        })

        this.$emit('next', 10)
      },
    },
    created() {
      this.address = this.member?.address ?? ''
      this.zipCode = this.member?.zip_code ?? ''
      this.phoneNumber = this.member?.phone_number ?? ''
      this.dateOfBirth = this.member?.date_of_birth ?? ''
    }
  }
</script>