<template>
  <div
    class="flex flex-col items-center min-h-screen py-10 bg-gray-100 sm:py-8"
  >
    <div
      class="
        w-full
        p-6
        mt-6
        overflow-hidden
        prose
        bg-white
        shadow-md
        sm:max-w-2xl sm:rounded-lg
      "
    >
      <h1>OFFICIAL RULES</h1>
      <p>
        <span>
          NO PURCHASE IS NECESSARY TO ENTER OR WIN. A PURCHASE DOES NOT INCREASE
          THE CHANCES OF WINNING.</span
        >
      </p>
      <p>
        <span
          >1. Eligibility: Sweepstakes (the &ldquo;Sweepstakes&rdquo;) is open
          only to those who sign up at the sweepstakes page located at </span
        ><span>https://www.crowdup.app</span
        ><span>
          and who are 18 as of the date of entry. The sweepstakes is only open
          to legal residents of the USA and is void where prohibited by law.
          Employees of Crowd Up (the &ldquo;Sponsor&rdquo;) their respective
          affiliates, subsidiaries, advertising and promotion agencies,
          suppliers and their immediate family members and/or those living in
          the same household of each are not eligible to participate in the
          Sweepstakes. The Sweepstakes is subject to all applicable federal,
          state and local laws and regulations. Void where prohibited.</span
        >
      </p>
      <p>
        <span
          >2. Agreement to Rules: By participating, you agree to be fully
          unconditionally bound by these Rules, and you represent and warrant
          that you meet the eligibility requirements set forth herein. In
          addition, you agree to accept the decisions of Crowd Up, as final and
          binding as it relates to the content. The Sweepstakes is subject to
          all applicable federal, state and local laws.</span
        >
      </p>
      <p>
        <span
          >3. Sweepstakes Period: Entries will be accepted online starting on or
          about 11/01/2021 and ending 07/15/2022. All online entries must be
          received by 07/15/2022 11:59PM EST.</span
        >
      </p>
      <p>
        <span
          >4. How to Enter: The Sweepstakes must be entered by submitting an
          entry using the online form provided on this Sweepstakes site, located
          at&nbsp; </span
        ><span>https://www.crowdup.app</span
        ><span
          >. The entry must fulfill all sweepstakes requirements, as specified,
          to be eligible to win a prize. Entries that are not complete or do not
          adhere to the rules or specifications may be disqualified at the sole
          discretion of Crowd Up. You may enter only once and you must fill in
          the information requested. You may not enter more times than indicated
          by using multiple email addresses, identities or devices in an attempt
          to circumvent the rules. If you use fraudulent methods or otherwise
          attempt to circumvent the rules your submission may be removed from
          eligibility at the sole discretion of Crowd Up.</span
        >
      </p>
      <p>
        <span
          >5. Prizes: Winners will receive list prizes/approximate retail value.
          Actual/appraised value may differ at time of prize award. The
          specifics of the prize shall be solely determined by the Sponsor. No
          cash or other prize substitution permitted except at Sponsor&rsquo;s
          discretion. The prize is non transferable. Any and all prize related
          expenses, including without limitation any and all federal, state,
          and/or local taxes shall be the sole responsibility of the winner. No
          substitution of prize or transfer/assignment of prize to others or
          request for the cash equivalent by winners is permitted. Acceptance of
          prize constitutes permission for Crowd Up to use the winner's name,
          likeness, and entry for purposes of advertising and trade without
          further compensation, unless prohibited by law.</span
        >
      </p>
      <p>
        <span
          >6. Odds: The odds of winning depend on the number of eligible entries
          received.</span
        >
      </p>
      <p>
        <span
          >7. Winner selection and notification: Winners of the Sweepstakes will
          be selected in a random drawing under the supervision of the Sponsor.
          Winners will be notified via email to the email address they entered
          the Sweepstakes within five (5) days following the winner selection.
          Crowd Up shall have no liability for a winner&rsquo;s failure to
          receive notices due to winners&rsquo; spam, junk e-mail or other
          security settings or for winners&rsquo; provision of incorrect or
          otherwise non-functioning contact information. If the selected winner
          cannot be contacted, is ineligible, fails to claim the prize within 72
          hours from the time award notification was sent, or fails to timely
          return a completed and executed declaration and releases as required,
          prize may be forfeited and an alternate winner selected.</span
        >
      </p>
      <p>
        <span
          >The receipt by the winner of the prize offered in this Sweepstakes is
          conditioned upon compliance with any and all federal and state laws
          and regulations. ANY VIOLATION OF THESE OFFICIAL RULES BY ANY WINNER
          (AT SPONSOR&rsquo;S SOLE DISCRETION) WILL RESULT IN SUCH
          WINNER&rsquo;S DISQUALIFICATION AS WINNER OF THE SWEEPSTAKES AND ALL
          PRIVILEGES AS WINNER WILL BE IMMEDIATELY TERMINATED.</span
        >
      </p>
      <p>
        <span
          >8. Rights Granted by you: By entering this content you understand
          that Crowd Up, anyone acting on behalf of Crowd Up, or its respective
          licensees, successors and assigns will have the right, where permitted
          by law, without any further notice, review or consent to print,
          publish, broadcast, distribute, and use, worldwide in any media now
          known or hereafter in perpetuity and throughout the World, your entry,
          including, without limitation, the entry and winner&rsquo;s name,
          portrait, picture, voice, likeness, image or statements about the
          Sweepstakes, and biographical information as news, publicity or
          information and for trade, advertising, public relations and
          promotional purposes without any further compensation.</span
        >
      </p>
      <p>
        <span
          >9. Terms: Crowd Up reserves the right, in its sole discretion to
          cancel, terminate, modify or suspend the Sweepstakes should (in its
          sole discretion) a virus, bugs, non-authorized human intervention,
          fraud or other causes beyond its control corrupt or affect the
          administration, security, fairness or proper conduct of the
          Sweepstakes. In such case, Crowd Up may select the recipients from all
          eligible entries received prior to and/or after (if appropriate) the
          action taken by Crowd Up. Crowd Up reserves the right at its sole
          discretion to disqualify any individual who tampers or attempts to
          tamper with the entry process or the operation of the Sweepstakes or
          website or violates these Terms &amp; Conditions.</span
        >
      </p>
      <p>
        <span
          >Crowd Up has the right, in its sole discretion, to maintain the
          integrity of the Sweepstakes, to void entries for any reason,
          including, but not limited to; multiple entries from the same user
          from different IP addresses; multiple entries from the same computer
          in excess of that allowed by sweepstakes rules; or the use of bots,
          macros or scripts or other technical means for entering.</span
        >
      </p>
      <p>
        <span
          >Any attempt by an entrant to deliberately damage any web site or
          undermine the legitimate operation of the sweepstakes may be a
          violation of criminal and civil laws and should such an attempt be
          made, Crowd Up reserves the right to seek damages from any such person
          to the fullest extent permitted by law.</span
        >
      </p>
      <p>
        <span
          >By entering the Sweepstakes you agree to receive email newsletters
          periodically from Crowd Up. You can opt-out of receiving this
          communication at any time by clicking the unsubscribe link in the
          newsletter.</span
        >
      </p>
      <p>
        <span
          >10. Limitation of Liability: By entering you agree to release and
          hold harmless Crowd Up and its subsidiaries, affiliates, advertising
          and promotion agencies, partners, representatives, agents, successors,
          assigns, employees, officers and directors from any liability,
          illness, injury, death, loss, litigation, claim or damage that may
          occur, directly or indirectly, whether caused by negligence or not,
          from (i) such entrant&rsquo;s participation in the sweepstakes and/or
          his/her acceptance, possession, use, or misuse of any prize or any
          portion thereof, (ii) technical failures of any kind, including but
          not limited to the malfunctioning of any computer, cable, network,
          hardware or software; (iii) the unavailability or inaccessibility of
          any transmissions or telephone or Internet service; (iv) unauthorized
          human intervention in any part of the entry process or the Promotion;
          (v) electronic or human error which may occur in the administration of
          the Promotion or the processing of entries.</span
        >
      </p>
      <p>
        <span
          >11. Disputes: THIS SWEEPSTAKES IS GOVERNED BY THE LAWS OF USA ,
          WITHOUT RESPECT TO CONFLICT OF LAW DOCTRINES. As a condition of
          participating in this Sweepstakes, participant agrees that any and all
          disputes which cannot be resolved between the parties, and causes of
          action arising out of or connected with this Sweepstakes, shall be
          resolved individually, without resort to any form of class action,
          exclusively before a court located in USA having jurisdiction.
          Further, in any such dispute, under no circumstances will participant
          be permitted to obtain awards for, and hereby waives all rights to
          claim punitive, incidental, or consequential damages, including
          reasonable attorneys&rsquo; fees, other than participant&rsquo;s
          actual out-of-pocket expenses (i.e. costs associated with entering
          this Sweepstakes), and participant further waives all rights to have
          damages multiplied or increased.</span
        >
      </p>
      <p>
        <span
          >12. Privacy Policy: Information submitted with an entry is subject to
          the Privacy Policy stated on the Crowd Up Web Site. To read the
          Privacy Policy, https://www.crowdup.app/privacy-policy click
          here.</span
        >
      </p>
      <p>
        <span
          >13. Winners List: To obtain a copy of the winner&rsquo;s name or a
          copy of these Official Rules, mail your request along with a stamped,
          self-addressed envelope to: Crowd Up, </span
        ><span>23 Veneto Ln., Aliso Viejo, CA 92656, USA</span
        ><span>. Requests must be received no later than 07/15/2021.</span>
      </p>
      <p>
        <span>14. Sponsor: The Sponsor of the Sweepstakes is Crowd Up, </span
        ><span>23 Veneto Ln., Aliso Viejo, CA 92656, USA</span><span>.</span>
      </p>
      <p>
        <span
          >15. This promotion is in no way sponsored, endorsed or administered
          by, or associated with Facebook, Twitter, Instagram, Snapchat,
          Youtube, Pinterest, LinkedIn or Google. You understand that you are
          providing your information to the owner of this sweepstakes and not to
          Facebook, Twitter, Instagram, Snapchat, Youtube, Pinterest, LinkedIn
          or Google.</span
        >
      </p>
    </div>
  </div>
</template>