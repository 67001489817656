<template>
  <div id="steps">
    <div
      class="
        flex flex-col
        min-h-screen
        sm:justify-center sm:py-12 sm:px-6
        lg:px-8
      "
    >
      <div class="mt-6 sm:mx-auto sm:w-full sm:max-w-md sm:mt-0">
        <img
          class="w-auto h-12 mx-auto"
          src="../assets/logo.png"
          alt="CrowdUp"
        />
      </div>

      <div class="mx-2 mt-8 sm:mx-auto sm:w-full sm:max-w-xs">
        <div class="p-4 rounded-md bg-yellow-50">
          <div class="flex">
            <div class="flex-shrink-0">
              <ExclamationIcon
                class="w-5 h-5 text-yellow-400"
                aria-hidden="true"
              />
            </div>

            <div class="flex-1 ml-3 md:flex md:justify-between">
              <p class="text-sm text-yellow-700">
                Only {{ currentStep.spotsRemaining }} spots remain, secure your
                reward while it's still available!
              </p>
            </div>
          </div>
        </div>

        <div class="relative pt-1 mt-5">
          <div class="flex items-center justify-between mb-2">
            <div>
              <span
                class="
                  flex
                  items-center
                  justify-center
                  px-3
                  py-1
                  text-xs
                  font-semibold
                  leading-normal
                  text-green-600
                  uppercase
                  bg-green-200
                  rounded-full
                "
              >
                <img
                  class="inline-block w-6 mr-2"
                  src="../assets/cash.png"
                  alt="Earn cash with CrowdUp"
                />
                {{ this.currentCoins }} coins earned
              </span>
            </div>

            <div class="text-right">
              <span class="inline-block text-xs font-semibold text-green-600">
                {{ completion }}%
              </span>
            </div>
          </div>

          <div
            class="flex h-2 mb-4 overflow-hidden text-xs bg-green-200 rounded"
          >
            <div
              :style="{ width: `${completion}%` }"
              class="
                flex flex-col
                justify-center
                text-center text-white
                bg-green-500
                shadow-none
                whitespace-nowrap
              "
            ></div>
          </div>
        </div>

        <div class="px-3 py-5 bg-white rounded-lg shadow sm:px-5">
          <transition name="fade" mode="out-in">
            <component
              :is="currentComponent"
              @next="next"
              @prev="prev"
              :token="token"
            />
          </transition>
        </div>
      </div>

      <section class="max-w-3xl mx-auto text-xs text-center text-gray-500 mt-4">
        <a
          class="underline hover:text-gray-700"
          @click.prevent="openModal"
          href="#"
          >Contact Us</a
        >
      </section>

      <section
        v-if="testimonial"
        class="py-8 overflow-hidden md:py-10 lg:py-12"
      >
        <div class="relative px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
          <div class="relative">
            <blockquote class="mt-10">
              <div
                class="
                  max-w-3xl
                  mx-auto
                  text-sm
                  font-medium
                  leading-8
                  text-center text-gray-900
                  sm:text-lg
                "
              >
                <p>&ldquo;{{ testimonial.content }}&rdquo;</p>
              </div>

              <footer class="mt-8">
                <div class="md:flex md:items-center md:justify-center">
                  <div class="md:flex-shrink-0">
                    <img
                      class="w-10 h-10 mx-auto rounded-full"
                      :src="require(`../assets/${testimonial.image}`)"
                    />
                  </div>

                  <div
                    class="
                      mt-3
                      text-center
                      md:mt-0 md:ml-4 md:flex md:items-center
                    "
                  >
                    <div class="text-base font-medium text-gray-900">
                      {{ testimonial.name }}
                    </div>

                    <svg
                      class="hidden w-5 h-5 mx-1 text-indigo-600 md:block"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                    >
                      <path d="M11 0h3L9 20H6l5-20z" />
                    </svg>

                    <div class="text-base font-medium text-gray-500">
                      {{ testimonial.location }}
                    </div>
                  </div>
                </div>
              </footer>
            </blockquote>
          </div>
        </div>
      </section>

      <section class="max-w-3xl mx-auto text-xs text-center text-gray-300">
        This site is not a part of the Facebook™ website or Facebook™ Inc.
        Additionally, this site is NOT endorsed by Facebook™ in any way.
        FACEBOOK™ is a trademark of FACEBOOK™, Inc.
      </section>

      <FooterLinks privacy="/privacy-policy" terms="/terms-and-conditions" sell="/ccpa-do-not-sell-my-information" cali="/ccpa-your-california-privacy-rights"></FooterLinks>

      <teleport to="body">
        <notification
          :show="notificationVisible"
          :message="notificationMessage"
        />
      </teleport>
    </div>

    <TransitionRoot as="template" :show="open">
      <Dialog
        as="div"
        class="fixed inset-0 z-10 overflow-y-auto"
        @close="open = false"
      >
        <div
          class="
            flex
            items-end
            justify-center
            min-h-screen
            px-4
            pt-4
            pb-20
            text-center
            sm:block sm:p-0
          "
        >
          <TransitionChild
            as="template"
            enter="ease-out duration-300"
            enter-from="opacity-0"
            enter-to="opacity-100"
            leave="ease-in duration-200"
            leave-from="opacity-100"
            leave-to="opacity-0"
          >
            <DialogOverlay
              class="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75"
            />
          </TransitionChild>

          <!-- This element is to trick the browser into centering the modal contents. -->
          <span
            class="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
            >&#8203;</span
          >
          <TransitionChild
            as="template"
            enter="ease-out duration-300"
            enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enter-to="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leave-from="opacity-100 translate-y-0 sm:scale-100"
            leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div
              class="
                inline-block
                px-4
                pt-5
                pb-4
                overflow-hidden
                text-left
                align-bottom
                transition-all
                transform
                bg-white
                rounded-lg
                shadow-xl
                sm:my-8 sm:align-middle sm:max-w-sm sm:w-full sm:p-6
              "
            >
              <div>
                <ContactForm />
              </div>
              <div class="mt-5 sm:mt-6">
                <button
                  type="button"
                  class="
                    inline-flex
                    justify-center
                    w-full
                    px-4
                    py-2
                    text-base
                    font-medium
                    text-white
                    bg-indigo-600
                    border border-transparent
                    rounded-md
                    shadow-sm
                    hover:bg-indigo-700
                    focus:outline-none
                    focus:ring-2
                    focus:ring-offset-2
                    focus:ring-indigo-500
                    sm:text-sm
                  "
                  @click="open = false"
                >
                  Submit
                </button>
              </div>
            </div>
          </TransitionChild>
        </div>
      </Dialog>
    </TransitionRoot>
  </div>
</template>

<script>
import { mapMutations } from "vuex";
import { types } from "@/store/members/types";
import { ExclamationIcon } from "@heroicons/vue/solid";
import Funnel from "@/mixins/funnel";
import Member from "@/mixins/member";
import Step1 from "@/components/funnel/Step1";
import Step2 from "@/components/funnel/Step2";
import Step3 from "@/components/funnel/Step3";
import Step4 from "@/components/funnel/Step4";
import Step5 from "@/components/funnel/Step5";
import Step6 from "@/components/funnel/Step6";
import Step7 from "@/components/funnel/Step7";
import Step8 from "@/components/funnel/Step8";
import Step9 from "@/components/funnel/Step9";
import Step10 from "@/components/funnel/Step10";
import Step11 from "@/components/funnel/Step11";
import Step12 from "@/components/funnel/Step12";
import Step13 from "@/components/funnel/Step13";
import Step14 from "@/components/funnel/Step14";
import Step15 from "@/components/funnel/Step15";
import Step16 from "@/components/funnel/Step16";
import Step17 from "@/components/funnel/Step17";
import Step18 from "@/components/funnel/Step18";
import Step19 from "@/components/funnel/Step19";
import Step20 from "@/components/funnel/Step20";
// import Step21 from '@/components/funnel/Step21'
// import Step22 from '@/components/funnel/Step22'
import Step23 from "@/components/funnel/Step23";
import Step24 from "@/components/funnel/Step24";
import Notification from "@/components/funnel/Notification";
import ContactForm from "@/components/ContactForm";
import FooterLinks from "@/components/FooterLinks.vue";
import {
  Dialog,
  DialogOverlay,
  TransitionChild,
  TransitionRoot,
} from "@headlessui/vue";

export default {
  name: "Funnel",
  mixins: [Funnel, Member],
  props: {
    step: { type: String, required: true },
    token: { type: String, default: null },
  },
  components: {
    Step1,
    Step2,
    Step3,
    Step4,
    Step5,
    Step6,
    Step7,
    Step8,
    Step9,
    Step10,
    Step11,
    Step12,
    Step13,
    Step14,
    Step15,
    Step16,
    Step17,
    Step18,
    Step19,
    Step20,
    // Step21,
    // Step22,
    Step23,
    Step24,
    Notification,
    ExclamationIcon,
    Dialog,
    DialogOverlay,
    TransitionChild,
    TransitionRoot,
    ContactForm,
    FooterLinks,
  },
  data() {
    return {
      open: false,
    };
  },
  methods: {
    openModal() {
      this.open = true;
    },
    ...mapMutations({
      setTrackingParams: types.mutations.SET_TRACKING_PARAMS,
    }),
  },
  computed: {
    completion() {
      return Math.floor((this.currentIndex / (this.funnel.length - 1)) * 100);
    },
  },
  mounted() {
    const params = new URLSearchParams(window.location.search);

    this.setTrackingParams({
      utm_source: params.get("utm_source"),
      utm_medium: params.get("utm_medium"),
      utm_content: params.get("utm_content"),
      utm_term: params.get("utm_term"),
      utm_channel: params.get("utm_channel"),
      gclid: params.get("gclid"),
      fbclid: params.get("fbclid"),
    });
  },
};
</script>

<style scoped>
#steps {
  background: url(../assets/bg-top-right.png) top right / 40% no-repeat,
    url(../assets/bg-bottom-left.png) bottom left / 40% no-repeat,
    url(../assets/bg-geometry.png) top left repeat;
}

@media only screen and (max-width: 600px) {
  #steps {
    background: url(../assets/bg-bottom-left.png) bottom left / 75% no-repeat,
      url(../assets/bg-geometry.png) top left repeat;
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.25s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>