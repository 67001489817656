<template>
  <center>
    <p class="mt-5 text-gray-400" style="font-size: 0.6rem">
      <a :href="privacyLink" target="_blank" class="text-underline inline-block m-1">Privacy
        Policy</a>
      <a :href="termsLink" target="_blank" class="text-underline inline-block m-1">Terms And
        Conditions</a>
      <a :href="sellLink" target="_blank" class="text-underline inline-block m-1">Do
        Not Sell My Information</a>
      <a :href="caliLink" target="_blank" class="text-underline inline-block m-1">California
        Residents</a>
    </p>
  </center>
</template>

<script>
export default {
  props: ["privacy", "terms", "sell", "cali"],
  data: () => ({
    privacyLink: "",
    termsLink: "",
    sellLink: "",
    caliLink: "",
  }),
  mounted() {
    this.privacyLink = this.privacy ? this.privacy : '/privacy-policy';
    this.termsLink = this.terms ? this.terms : '/terms-and-conditions';
    this.sellLink = this.sell ? this.sell : '/ccpa-do-not-sell-my-information';
    this.caliLink = this.cali ? this.cali : '/ccpa-your-california-privacy-rights';
  }
};
</script>
