import { mapGetters, mapMutations } from 'vuex'
import { types } from '@/store/funnels/types'

const testimonials = [
  {
    name: 'Jordan Johnson',
    image: 'jordan-johnson.png',
    location: 'Blasdell, NY',
    content: 'When I first started with CrowdUp I was skeptical about getting paid for filling out surveys. But you know what, the minute I started to get some cash coming in, I signed up for many more and made an extra $500 in my first month. It is a great way to earn a little bit of money from home!',
  },
  {
    name: 'Shane Carlyle',
    image: 'shane-carlyle.png',
    location: 'Lawrenceville, IL',
    content: 'CrowdUp is the best survey-taking website I’ve ever tried. It was easy to use, and the surveys were engaging. Not only did I feel that I\'m helping someone, but also being entertained at the same time!',
  },
  {
    name: 'Isabelle Gardner',
    image: 'isabelle-gardner.png',
    location: 'Hasson Heights, PA',
    content: 'I was really excited to try CrowdUp out. The surveys were fun and I always got rewarded with cash or other prizes. I wish there was more diversity in the available surveys or some type of reward points that could be redeemed for different things. Overall, it’s a great website and I’ll continue checking it for more surveys.',
  }
]

export default {
  data() {
    return {
      testimonial: null,
      notificationVisible: false,
      notificationMessage: '',
    }
  },
  computed: {
    ...mapGetters({
      funnel: types.getters.GET_STEPS,
      currentCoins: types.getters.GET_CURRENT_COINS,
      currentIndex: types.getters.GET_CURRENT_INDEX,
      currentStep: types.getters.GET_CURRENT_STEP,
      currentComponent: types.getters.GET_CURRENT_COMPONENT,
    }),
  },
  methods: {
    ...mapMutations({
      setCurrentIndex: types.mutations.SET_CURRENT_INDEX,
      incrementCurrentCoins: types.mutations.INCREMENT_CURRENT_COINS,
      incrementCurrentIndex: types.mutations.INCREMENT_CURRENT_INDEX,
      decrementCurrentIndex: types.mutations.DECREMENT_CURRENT_INDEX,
      completeStep: types.mutations.COMPLETE_STEP,
    }),

    next(coins = 0) {
      if ((this.currentIndex + 1) in this.funnel) {
        if (this.currentStep.complete === false) {
          this.incrementCurrentCoins(coins)
          this.completeStep(this.currentIndex)
        }

        this.incrementCurrentIndex()
        this.push(this.currentStep)

        if (this.currentStep.notification) {
          this.notificationMessage = this.currentStep.notification
          this.notificationVisible = true

          setTimeout(() => {
            this.notificationVisible = false
          }, 2500)
       }
      }
    },

    prev() {
      if ((this.currentIndex - 1) in this.funnel) {
        this.decrementCurrentIndex()
        this.push(this.currentStep)
      }
    },

    push(step) {
      this.$router.push({ 
        path: `/funnel/${step.permalink}`
      })

      this.$gtag.pageview(this.$route)
    }
  },
  created() {
    this.testimonial = testimonials[Math.floor(Math.random() * testimonials.length)]

    const index = this.$_.findIndex(this.funnel, { 
      permalink: this.step 
    })

    if (index in this.funnel) {
      this.setCurrentIndex(index)
    }

    else {
      this.funnel.some((step, index) => {
        if (step.complete === false) {
          this.setCurrentIndex(index)
          this.push(step)

          return true
        }
      })
    }
  }
}