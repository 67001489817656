<template>
  <div id="step11">
    <div class="flex flex-wrap justify-center mb-4">
      <img class="w-26 h-24" src="../../assets/creditcard.png" alt="What is your credit score?" />
    </div>

    <h1 class="block text-2xl text-center font-extrabold tracking-tight text-gray-900 sm:text-2xl px-10">
      What is your credit score?
    </h1>
    
    <form>
      <input id="leadid_token" name="universal_leadid" type="hidden" />

      <button @click.prevent="next('550')" type="submit" class="mt-4 w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
        Less than 550
      </button>

      <button @click.prevent="next('550-649')" type="submit" class="mt-4 w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
        Between 550-649
      </button>

      <button @click.prevent="next('650-749')" type="submit" class="mt-4 w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
        Between 650-749
      </button>

      <button @click.prevent="next('749')" type="submit" class="mt-4 w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
        Greater than 749
      </button>
    </form>
  </div>
</template>

<script>
  import Member from '@/mixins/member'

  export default {
    mixins: [ Member ],
    methods: {
      next(answer) {
        this.setProfilingQuestions({
          credit_rating: answer,
        })
        
        if (this.member) {
          this.updateMember({
            id: this.member.id
          })
        }

        this.$emit('next', 10)
      },
    }
  }
</script>