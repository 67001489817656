<template>
  <div id="step1">
    <img class="w-3/4 mx-auto mb-2" src="../../assets/survey-to-cash.png" alt="Survey to Cash" />

    <h1 class="block text-2xl font-extrabold tracking-tight text-center text-gray-900 sm:text-2xl">
      Take our survey and earn cash rewards!
    </h1>

    <p class="mt-2 mb-4 text-center text-gray-500">
      Earn up to $100 by participating! It takes no longer than a coffee break.
    </p>

    <form @submit.prevent="next">
      <input id="leadid_token" name="universal_leadid" type="hidden" />

      <button class="flex justify-center w-full px-4 py-2 text-sm font-medium text-white bg-indigo-600 border border-transparent rounded-md shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
        Begin Survey
      </button>
    </form>

    <p class="px-2 mt-2 text-xs text-center text-gray-500">
      By clicking the button above you are agreeing to our <router-link class="underline hover:text-gray-700" target="_blank" :to="{name: 'PrivacyPolicy'}">privacy policy</router-link> and survey rules.
    </p>
  </div>
</template>

<script>
  export default {
    methods: {
      next() {
        this.$emit('next')
      }
    }
  }
</script>