<template>
  <div class="m-auto bg-white rounded-lg ">
    <div class="my-5">
      <div class="flex justify-center w-full m-auto sm:w-64 flex-nowrap">
        <div
          :class="`w-${randomWidths[0]}/12`"
          class="grid items-center justify-center h-20 text-3xl font-bold text-indigo-700 bg-indigo-200 rounded-l-lg "
        >
          {{ lowestPercentage }}%
        </div>
        <div
          :class="`w-${randomWidths[1]}/12`"
          class="grid items-center justify-center h-20 text-3xl font-bold text-indigo-700 bg-yellow-200 rounded-r-lg "
        >
          {{ highestPercentage }}%
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    randomPercentages() {
      return this.getRandomPercentages();
    },
    lowestPercentage() {
      return this.randomPercentages[0];
    },
    highestPercentage() {
      return this.randomPercentages[1];
    },
    randomWidths() {
      let lowestWidth = Math.ceil(0.12 * this.lowestPercentage);
      let highestWidth = 12 - lowestWidth;

      return [lowestWidth, highestWidth];
    },
  },
  methods: {
    getRandomPercentages() {
      let lowestBiasNumber = 20;
      let highestBiasNumber = 70;

      let percentageOne = this.generateRandomInteger(
        lowestBiasNumber,
        highestBiasNumber
      );

      let percentageTwo = 100 - percentageOne;

      return [percentageOne, percentageTwo];
    },

    generateRandomInteger(min, max) {
      return Math.floor(Math.random() * (max - min)) + min;
    },
  },
};
</script>
