<template>
  <div id="step16">
    <div class="flex flex-wrap justify-center mb-4">
      <img class="w-24 h-24" src="../../assets/questions.png" alt="We have more questions" />
    </div>
    
    <h1 class="block text-2xl text-center font-extrabold tracking-tight text-gray-900 sm:text-2xl">
      Survey Time!
    </h1>

    <p class="mt-2 mb-4 text-center text-gray-500">
      Let’s see which surveys are for you!
    </p>

    <form>
      <input id="leadid_token" name="universal_leadid" type="hidden" />

      <div class="mt-4 space-y-3">
        <SwitchGroup as="div" class="flex items-center justify-between">
          <span class="flex-grow flex flex-col mr-2">
            <SwitchLabel as="span" class="text-sm font-medium text-gray-900" passive>
              Continuing Education
            </SwitchLabel>
            <SwitchDescription as="span" class="text-sm text-gray-500">Interested in going back to school or furthering your education?</SwitchDescription>
          </span>

          <Switch v-model="furtherEducation" :class="[furtherEducation ? 'bg-indigo-600' : 'bg-gray-200', 'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500']">
            <span class="sr-only">Use setting</span>
            <span :class="[furtherEducation ? 'translate-x-5' : 'translate-x-0', 'pointer-events-none relative inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200']">
              <span :class="[furtherEducation ? 'opacity-0 ease-out duration-100' : 'opacity-100 ease-in duration-200', 'absolute inset-0 h-full w-full flex items-center justify-center transition-opacity']" aria-hidden="true">
                <svg class="h-3 w-3 text-gray-400" fill="none" viewBox="0 0 12 12">
                  <path d="M4 8l2-2m0 0l2-2M6 6L4 4m2 2l2 2" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
              </span>
              <span :class="[furtherEducation ? 'opacity-100 ease-in duration-200' : 'opacity-0 ease-out duration-100', 'absolute inset-0 h-full w-full flex items-center justify-center transition-opacity']" aria-hidden="true">
                <svg class="h-3 w-3 text-indigo-600" fill="currentColor" viewBox="0 0 12 12">
                  <path d="M3.707 5.293a1 1 0 00-1.414 1.414l1.414-1.414zM5 8l-.707.707a1 1 0 001.414 0L5 8zm4.707-3.293a1 1 0 00-1.414-1.414l1.414 1.414zm-7.414 2l2 2 1.414-1.414-2-2-1.414 1.414zm3.414 2l4-4-1.414-1.414-4 4 1.414 1.414z" />
                </svg>
              </span>
            </span>
          </Switch>
        </SwitchGroup>

        <SwitchGroup as="div" class="flex items-center justify-between">
          <span class="flex-grow flex flex-col mr-2">
            <SwitchLabel as="span" class="text-sm font-medium text-gray-900" passive>
              Job Hunting
            </SwitchLabel>
            <SwitchDescription as="span" class="text-sm text-gray-500">Get real-time alerts in your area. Sign up for Find Prime Jobs today!</SwitchDescription>
          </span>

          <Switch v-model="jobSearching" :class="[jobSearching ? 'bg-indigo-600' : 'bg-gray-200', 'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500']">
            <span class="sr-only">Use setting</span>
            <span :class="[jobSearching ? 'translate-x-5' : 'translate-x-0', 'pointer-events-none relative inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200']">
              <span :class="[jobSearching ? 'opacity-0 ease-out duration-100' : 'opacity-100 ease-in duration-200', 'absolute inset-0 h-full w-full flex items-center justify-center transition-opacity']" aria-hidden="true">
                <svg class="h-3 w-3 text-gray-400" fill="none" viewBox="0 0 12 12">
                  <path d="M4 8l2-2m0 0l2-2M6 6L4 4m2 2l2 2" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
              </span>
              <span :class="[jobSearching ? 'opacity-100 ease-in duration-200' : 'opacity-0 ease-out duration-100', 'absolute inset-0 h-full w-full flex items-center justify-center transition-opacity']" aria-hidden="true">
                <svg class="h-3 w-3 text-indigo-600" fill="currentColor" viewBox="0 0 12 12">
                  <path d="M3.707 5.293a1 1 0 00-1.414 1.414l1.414-1.414zM5 8l-.707.707a1 1 0 001.414 0L5 8zm4.707-3.293a1 1 0 00-1.414-1.414l1.414 1.414zm-7.414 2l2 2 1.414-1.414-2-2-1.414 1.414zm3.414 2l4-4-1.414-1.414-4 4 1.414 1.414z" />
                </svg>
              </span>
            </span>
          </Switch>
        </SwitchGroup>

        <SwitchGroup as="div" class="flex items-center justify-between">
          <span class="flex-grow flex flex-col mr-2">
            <SwitchLabel as="span" class="text-sm font-medium text-gray-900" passive>
              Family First
            </SwitchLabel>
            <SwitchDescription as="span" class="text-sm text-gray-500">Do you want your family to be protected even after you're gone?</SwitchDescription>
          </span>

          <Switch v-model="finalExpense" :class="[finalExpense ? 'bg-indigo-600' : 'bg-gray-200', 'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500']">
            <span class="sr-only">Use setting</span>
            <span :class="[finalExpense ? 'translate-x-5' : 'translate-x-0', 'pointer-events-none relative inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200']">
              <span :class="[finalExpense ? 'opacity-0 ease-out duration-100' : 'opacity-100 ease-in duration-200', 'absolute inset-0 h-full w-full flex items-center justify-center transition-opacity']" aria-hidden="true">
                <svg class="h-3 w-3 text-gray-400" fill="none" viewBox="0 0 12 12">
                  <path d="M4 8l2-2m0 0l2-2M6 6L4 4m2 2l2 2" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
              </span>
              <span :class="[finalExpense ? 'opacity-100 ease-in duration-200' : 'opacity-0 ease-out duration-100', 'absolute inset-0 h-full w-full flex items-center justify-center transition-opacity']" aria-hidden="true">
                <svg class="h-3 w-3 text-indigo-600" fill="currentColor" viewBox="0 0 12 12">
                  <path d="M3.707 5.293a1 1 0 00-1.414 1.414l1.414-1.414zM5 8l-.707.707a1 1 0 001.414 0L5 8zm4.707-3.293a1 1 0 00-1.414-1.414l1.414 1.414zm-7.414 2l2 2 1.414-1.414-2-2-1.414 1.414zm3.414 2l4-4-1.414-1.414-4 4 1.414 1.414z" />
                </svg>
              </span>
            </span>
          </Switch>
        </SwitchGroup>

        <SwitchGroup as="div" class="flex items-center justify-between">
          <span class="flex-grow flex flex-col mr-2">
            <SwitchLabel as="span" class="text-sm font-medium text-gray-900" passive>
              Considering Health
            </SwitchLabel>
            <SwitchDescription as="span" class="text-sm text-gray-500">Are you diabetic and currently using insulin?</SwitchDescription>
          </span>

          <Switch v-model="insulinUser" :class="[insulinUser ? 'bg-indigo-600' : 'bg-gray-200', 'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500']">
            <span class="sr-only">Use setting</span>
            <span :class="[insulinUser ? 'translate-x-5' : 'translate-x-0', 'pointer-events-none relative inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200']">
              <span :class="[insulinUser ? 'opacity-0 ease-out duration-100' : 'opacity-100 ease-in duration-200', 'absolute inset-0 h-full w-full flex items-center justify-center transition-opacity']" aria-hidden="true">
                <svg class="h-3 w-3 text-gray-400" fill="none" viewBox="0 0 12 12">
                  <path d="M4 8l2-2m0 0l2-2M6 6L4 4m2 2l2 2" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
              </span>
              <span :class="[insulinUser ? 'opacity-100 ease-in duration-200' : 'opacity-0 ease-out duration-100', 'absolute inset-0 h-full w-full flex items-center justify-center transition-opacity']" aria-hidden="true">
                <svg class="h-3 w-3 text-indigo-600" fill="currentColor" viewBox="0 0 12 12">
                  <path d="M3.707 5.293a1 1 0 00-1.414 1.414l1.414-1.414zM5 8l-.707.707a1 1 0 001.414 0L5 8zm4.707-3.293a1 1 0 00-1.414-1.414l1.414 1.414zm-7.414 2l2 2 1.414-1.414-2-2-1.414 1.414zm3.414 2l4-4-1.414-1.414-4 4 1.414 1.414z" />
                </svg>
              </span>
            </span>
          </Switch>
        </SwitchGroup>

        <SwitchGroup as="div" class="flex items-center justify-between">
          <span class="flex-grow flex flex-col mr-2">
            <SwitchLabel as="span" class="text-sm font-medium text-gray-900" passive>
              Disability
            </SwitchLabel>
            <SwitchDescription as="span" class="text-sm text-gray-500">Have you been on disability for over two years?</SwitchDescription>
          </span>

          <Switch v-model="disabledUser" :class="[disabledUser ? 'bg-indigo-600' : 'bg-gray-200', 'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500']">
            <span class="sr-only">Use setting</span>
            <span :class="[disabledUser ? 'translate-x-5' : 'translate-x-0', 'pointer-events-none relative inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200']">
              <span :class="[disabledUser ? 'opacity-0 ease-out duration-100' : 'opacity-100 ease-in duration-200', 'absolute inset-0 h-full w-full flex items-center justify-center transition-opacity']" aria-hidden="true">
                <svg class="h-3 w-3 text-gray-400" fill="none" viewBox="0 0 12 12">
                  <path d="M4 8l2-2m0 0l2-2M6 6L4 4m2 2l2 2" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
              </span>
              <span :class="[disabledUser ? 'opacity-100 ease-in duration-200' : 'opacity-0 ease-out duration-100', 'absolute inset-0 h-full w-full flex items-center justify-center transition-opacity']" aria-hidden="true">
                <svg class="h-3 w-3 text-indigo-600" fill="currentColor" viewBox="0 0 12 12">
                  <path d="M3.707 5.293a1 1 0 00-1.414 1.414l1.414-1.414zM5 8l-.707.707a1 1 0 001.414 0L5 8zm4.707-3.293a1 1 0 00-1.414-1.414l1.414 1.414zm-7.414 2l2 2 1.414-1.414-2-2-1.414 1.414zm3.414 2l4-4-1.414-1.414-4 4 1.414 1.414z" />
                </svg>
              </span>
            </span>
          </Switch>
        </SwitchGroup>

        <SwitchGroup as="div" class="flex items-center justify-between">
          <span class="flex-grow flex flex-col mr-2">
            <SwitchLabel as="span" class="text-sm font-medium text-gray-900" passive>
              Healthcare Savings
            </SwitchLabel>
            <SwitchDescription as="span" class="text-sm text-gray-500">Are you currently on Medicare and interested in Medicare savings?</SwitchDescription>
          </span>

          <Switch v-model="medicareSavings" :class="[medicareSavings ? 'bg-indigo-600' : 'bg-gray-200', 'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500']">
            <span class="sr-only">Use setting</span>
            <span :class="[medicareSavings ? 'translate-x-5' : 'translate-x-0', 'pointer-events-none relative inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200']">
              <span :class="[medicareSavings ? 'opacity-0 ease-out duration-100' : 'opacity-100 ease-in duration-200', 'absolute inset-0 h-full w-full flex items-center justify-center transition-opacity']" aria-hidden="true">
                <svg class="h-3 w-3 text-gray-400" fill="none" viewBox="0 0 12 12">
                  <path d="M4 8l2-2m0 0l2-2M6 6L4 4m2 2l2 2" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
              </span>
              <span :class="[medicareSavings ? 'opacity-100 ease-in duration-200' : 'opacity-0 ease-out duration-100', 'absolute inset-0 h-full w-full flex items-center justify-center transition-opacity']" aria-hidden="true">
                <svg class="h-3 w-3 text-indigo-600" fill="currentColor" viewBox="0 0 12 12">
                  <path d="M3.707 5.293a1 1 0 00-1.414 1.414l1.414-1.414zM5 8l-.707.707a1 1 0 001.414 0L5 8zm4.707-3.293a1 1 0 00-1.414-1.414l1.414 1.414zm-7.414 2l2 2 1.414-1.414-2-2-1.414 1.414zm3.414 2l4-4-1.414-1.414-4 4 1.414 1.414z" />
                </svg>
              </span>
            </span>
          </Switch>
        </SwitchGroup>
      </div>

      <div class="mt-6">
        <button @click.prevent="next" type="submit" class="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
          Continue
        </button>
      </div>
    </form>
  </div>
</template>

<script>
  import Member from '@/mixins/member'
  import { Switch, SwitchDescription, SwitchGroup, SwitchLabel } from '@headlessui/vue'

  export default {
    mixins: [ Member ],
    components: {
      Switch,
      SwitchDescription,
      SwitchGroup,
      SwitchLabel,
    },
    data() {
      return {
        furtherEducation: false,
        jobSearching: false,
        finalExpense: false,
        insulinUser: false,
        disabledUser: false,
        medicareSavings: false,
      }
    },
    methods: {
      next() {
        this.setClickOffers({
          further_education: this.furtherEducation,
          job_searching: this.jobSearching,
          final_expense: this.finalExpense,
          insulin_user: this.insulinUser,
          disabled_user: this.disabledUser,
          medicare_savings: this.medicareSavings,
        })

        if (this.member) {
          this.updateMember({
            id: this.member.id
          })
        }

        this.$emit('next', 10)
      },
    },
    created() {
      if (this.member) {
        this.furtherEducation = this.member.click_offers.further_education
        this.jobSearching = this.member.click_offers.job_searching
        this.finalExpense = this.member.click_offers.final_expense
        this.insulinUser = this.member.click_offers.insulin_user
        this.disabledUser = this.member.click_offers.disabled_user
        this.medicareSavings = this.member.click_offers.medicare_savings
      }
    }
  }
</script>