import { http } from '@/http'
import { types } from '@/store/members/types'

const state = () => ({
  miscellaneous_questions: {
    iphone_android: null,
    biden_trump: null,
    dog_cat: null,
    screentime: null,
  },
  profiling_questions: {
    own_home: null,
    own_car: null,
    total_kids: null,
    credit_rating: null,
    job_type: null,
    tobacco_user: null,
  },
  click_offers: {
    further_education: null,
    job_searching: null,
    final_expense: null,
    insulin_user: null,
    disabled_user: null,
    medicare_savings: null,
    class_action: null,
    hernia_mesh: null,
    zantac: null,
    talcum_powder: null,
    talcum_powder_expl: null,
    car_injury: null,
    car_injury_expl: null,
    bankruptcy: null,
    bankruptcy_expl: null,
    personal_injury: null,
    personal_injury_expl: null,
    clergy_abuse: null,
  },
  internal_offers: {
    health: null,
    medicare: null,
    solar: null,
    automotive: null,
    refinance: null,
  },
  app_offers: [
    { url: 'https://www.abp4clk.com/3KFRZ6/225JFQ/', image: require('../../assets/app_offers/choice_gold.png'), headline: 'Get approved immediately for a $500 credit line!', clicked: false },
    { url: 'https://www.abp4clk.com/3KFRZ6/XCQZJ/', image: require('../../assets/app_offers/unemployment_benefits.png'), headline: 'Get unemployment help and a $1400 stimulus check!', clicked: false },
    { url: 'https://www.abp4clk.com/3KFRZ6/2CTPL1/', image: require('../../assets/app_offers/travel.png'), headline: 'Get a $1500 travel credit now!', clicked: false },
    { url: 'https://www.abp4clk.com/3KFRZ6/ZQMP5/', image: require('../../assets/app_offers/acg.png'), headline: 'Find high-paying jobs in your area!', clicked: false },
  ],
  member: null,
  utmSource: '',
  utmMedium: '',
  utmContent: '',
  utmTerm: '',
  utmChannel: '',
  gclid: '',
  fbclid: '',
})

const getters = {
  [types.getters.GET_MEMBER]: (state) => state.member,
  [types.getters.GET_MISCELLANEOUS_QUESTIONS]: (state) => state.miscellaneous_questions,
  [types.getters.GET_PROFILING_QUESTIONS]: (state) => state.profiling_questions,
  [types.getters.GET_CLICK_OFFERS]: (state) => state.click_offers,
  [types.getters.GET_INTERNAL_OFFERS]: (state) => state.internal_offers,
  [types.getters.GET_APP_OFFERS]: (state) => state.app_offers,
}

const actions = {
  [types.actions.CREATE_MEMBER]: async ({ commit, state }, {
    first_name,
    last_name,
    email_address,
    jornaya,
    trusted_form,
  }) => {
    await http.post(`/funnel`, {
      first_name: first_name,
      last_name: last_name,
      email_address: email_address,
      jornaya: jornaya,
      trusted_form: trusted_form,
      utm_source: state.utmSource,
      utm_medium: state.utmMedium,
      utm_content: state.utmContent,
      utm_term: state.utmTerm,
      utm_channel: state.utmChannel,
      gclid: state.gclid,
      fbclid: state.fbclid
    }).then(response => {
      commit(types.mutations.SET_MEMBER, response.data.data)
    })
  },
  [types.actions.LOAD_MEMBER]: async ({ commit }, token) => {
    await http.get(`/funnel/${token}`).then(response => {
      commit(types.mutations.SET_MEMBER, response.data.data)
    })
  },

  [types.actions.UPDATE_MEMBER]: async ({ commit, state }, { id, data }) => {
    await http.put(`/funnel/${id}`, {
      ...data,
      miscellaneous_questions: state.miscellaneous_questions,
      profiling_questions: state.profiling_questions,
      click_offers: state.click_offers,
      internal_offers: state.internal_offers,
      app_offers: state.app_offers,
    }).then(response => {
      commit(types.mutations.SET_MEMBER, response.data.data)
    })
  }
}

const mutations = {
  [types.mutations.SET_MEMBER]: (state, member) => {
    state.member = member
  },
  [types.mutations.SET_MISCELLANEOUS_QUESTIONS]: (state, questions) => {
    state.miscellaneous_questions = {
      ...state.miscellaneous_questions,
      ...questions,
    }
  },
  [types.mutations.SET_PROFILING_QUESTIONS]: (state, questions) => {
    state.profiling_questions = {
      ...state.profiling_questions,
      ...questions,
    }
  },
  [types.mutations.SET_CLICK_OFFERS]: (state, questions) => {
    state.click_offers = {
      ...state.click_offers,
      ...questions,
    }
  },
  [types.mutations.SET_INTERNAL_OFFERS]: (state, questions) => {
    state.internal_offers = {
      ...state.internal_offers,
      ...questions,
    }
  },
  [types.mutations.SET_TRACKING_PARAMS]: (state, params) => {
    state.utmSource = params.utm_source
    state.utmMedium = params.utm_medium
    state.utmContent = params.utm_content
    state.utmTerm = params.utm_term
    state.utmChannel = params.utm_channel
    state.gclid = params.gclid
    state.fbclid = params.fbclid
  },
  [types.mutations.COMPLETE_APP_OFFER]: (state, index) => {
    state.app_offers[index].clicked = true
  }
}

export default {
  state,
  actions,
  getters,
  mutations,
}