<template>
  <div id="step23">
    <h1 class="block text-2xl text-center font-extrabold tracking-tight text-gray-900 sm:text-2xl">
      This is the last step!
    </h1>

    <p class="mt-2 mb-4 text-center text-gray-500">
      Tap and download at least three of 3 offers to complete your survey!
    </p>

    <div v-for="(offer, index) in appOffers" v-bind:key="index">
      <div class="border-t-2 py-5 my-1 table">
        <div class="table-cell w-1/2">
          <img class="w-26 object-contain" :src="offer.image"/>
          <p class="text-xs text-gray-400 mt-5">{{offer.headline}}</p>
        </div>

        <div class="table-cell text-right align-middle">
          <a @click="completeOffer(index)" :href="offer.url" target="_blank" class="disabled:opacity-50 disabled:cursor-not-allowed w-full justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-green-500 hover:bg-500-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500">
            Redeem
          </a>
        </div>
      </div>
    </div>

    <form>
      <input id="leadid_token" name="universal_leadid" type="hidden" />

      <button @click.prevent="next" :disabled="! offersAreCompleted" type="submit" class="disabled:opacity-50 disabled:cursor-not-allowed w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
        Complete Survey
      </button>
    </form>
  </div>
</template>

<script>
  import Member from '@/mixins/member'

  export default {
    mixins: [ Member ],
    computed: {
      offersAreCompleted() {
        return this.$_.filter(this.appOffers, { clicked: true }).length >= 3
      },
    },
    methods: {
      completeOffer(index) {
        this.completeAppOffer(index)
        this.updateMember({ id: this.member.id })
      },
      next() {
        this.$emit('next')
      },
    }
  }
</script>