<template>
  <div id="step8">
    <div class="flex flex-wrap justify-center mb-4">
      <img class="w-26 h-24" src="../../assets/car.png" alt="Do you own a car?" />
    </div>
    
    <h1 class="block text-2xl text-center font-extrabold tracking-tight text-gray-900 sm:text-2xl">
      Do you own a car?
    </h1>

    <form>
      <input id="leadid_token" name="universal_leadid" type="hidden" />
      
      <div class="mt-4 grid grid-cols-2 gap-3">
        <div>
          <button @click.prevent="next('no')" type="submit" class="w-full flex justify-center py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
            No
          </button>
        </div>

        <div>
          <button @click.prevent="next('yes')" type="submit" class="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
            Yes
          </button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
  import Member from '@/mixins/member'

  export default {
    mixins: [ Member ],
    methods: {
      next(answer) {
        this.setProfilingQuestions({
          own_car: answer,
        })
        
        if (this.member) {
          this.updateMember({
            id: this.member.id
          })
        }

        this.$emit('next', 10)
      },
    }
  }
</script>