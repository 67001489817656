<template>
  <div id="step6">
    <div class="flex flex-wrap justify-center mb-4">
      <img class="w-24 h-24" src="../../assets/email.png" alt="Contact Information" />
    </div>

    <h1 class="block text-2xl text-center font-extrabold tracking-tight text-gray-900 sm:text-2xl">
      We need your email!
    </h1>

    <p class="mt-2 mb-4 text-center text-gray-500">
      If you instant earn $100, we need to know where to send your check!
    </p>

    <form @submit.prevent="next">
      <input id="leadid_token" name="universal_leadid" type="hidden" />

      <div class="mt-4 space-y-3">
        <div>
          <label for="first_name" class="block text-sm font-medium text-gray-700">
            First Name
          </label>

          <div class="mt-1">
            <input v-model="firstName" id="first_name" type="text" autocomplete="first_name" required class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" />
          </div>
        </div>

        <div>
          <label for="last_name" class="block text-sm font-medium text-gray-700">
            Last Name
          </label>

          <div class="mt-1">
            <input v-model="lastName" id="last_name" type="text" autocomplete="last_name" required class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" />
          </div>
        </div>

        <div>
          <label for="email" class="block text-sm font-medium text-gray-700">
            Email address
          </label>

          <div class="mt-1">
            <input v-model="email" id="email" type="email" autocomplete="email" required class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" />
          </div>
        </div>
      </div>

      <div class="mt-6">
        <button :disabled="! formIsValid" type="submit" class="disabled:opacity-50 disabled:cursor-not-allowed w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
          Continue
        </button>
      </div>
    </form>
  </div>
</template>

<script>
  import Member from '@/mixins/member'

  export default {
    mixins: [ Member ],
    computed: {
      firstNameIsValid() {
        return ! (this.firstName === '')
      },
      lastNameIsValid() {
        return ! (this.lastName === '')
      },
      emailIsValid() {
        return ! (this.email === '')
      },
      formIsValid() {
        return this.firstNameIsValid &&
               this.lastNameIsValid  &&
               this.emailIsValid
      }
    },
    methods: {
      next() {
        if (this.member) {
          this.updateMember({
            id: this.member.id,
            data: {
              first_name: this.firstName,
              last_name: this.lastName,
              email_address: this.email,
              jornaya: this.leadId,
              trusted_form: this.trustedForm,
            }
          })
        }

        else {
          this.createMember({
            first_name: this.firstName,
            last_name: this.lastName,
            email_address: this.email,
            jornaya: this.leadId,
            trusted_form: this.trustedForm,
          })
        }

        this.$emit('next', 10)
      },
    },
    data() {
      return {
        firstName: '',
        lastName: '',
        email: '',
      }
    },
    created() {
      this.firstName = this.member?.first_name ?? ''
      this.lastName = this.member?.last_name ?? ''
      this.email = this.member?.email_address ?? ''
    }
  }
</script>