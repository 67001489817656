<template>
  <div id="step18">
    <div class="flex flex-wrap justify-center mb-4">
      <img class="w-24 h-24" src="../../assets/check.png" alt="Cash Money" />
    </div>

    <h1 class="block text-2xl text-center font-extrabold tracking-tight text-gray-900 sm:text-2xl">
      Time for you to make even more money!
    </h1>

    <p class="mt-2 mb-4 text-center text-gray-500">
      Based on your answers, we found offers that will help you save money.
    </p>

    <form>
      <input id="leadid_token" name="universal_leadid" type="hidden" />

      <button @click.prevent="next" type="submit" class="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
        Continue
      </button>
    </form>
  </div>
</template>

<script>
  export default {
    methods: {
      next() {
        this.$emit('next')
      },
    }
  }
</script>