import axios from "axios"

export async function validatePhoneNumber(phone_number) {
	return new Promise((resolve) => {
		axios.post(`https://crowdhub.app/api/validate`,{
			phone_number
		})
			.then(response => {
				resolve(response.data)
			})
	})
}

export async function processValidatedPhoneNumber(
	phone_number,
	utm_source,
	utm_medium,
	utm_content,
	utm_term,
	utm_channel,
	gclid,
	fbclid,
	jornaya,
	trusted_form
	) {
	const payload = {
		phone_number,
		utm_source,
		utm_medium,
		utm_content,
		utm_term,
		utm_channel,
		gclid,
		fbclid,
		jornaya,
		trusted_form,
	};

	return new Promise((resolve) => {
		axios.post(`https://crowdhub.app/api/append`, payload)
			.then(response => {
				resolve(response.data)
			})
	})
}

export function handleNewUserData(payload) {
	return new Promise((resolve) => {
		axios.post(`https://crowdhub.app/api/lead`, payload)
			.then(response => {
				resolve(response.data)
			})
	})
}