export const types = {
  getters: {
    GET_MEMBER: 'GET_MEMBER',
    GET_MISCELLANEOUS_QUESTIONS: 'GET_MISCELLANEOUS_QUESTIONS',
    GET_PROFILING_QUESTIONS: 'GET_PROFILING_QUESTIONS',
    GET_CLICK_OFFERS: 'GET_CLICK_OFFERS',
    GET_INTERNAL_OFFERS: 'GET_INTERNAL_OFFERS',
    GET_APP_OFFERS: 'GET_APP_OFFERS',
  },

  actions: {
    CREATE_MEMBER: 'CREATE_MEMBER',
    LOAD_MEMBER: 'LOAD_MEMBER',
    UPDATE_MEMBER: 'UPDATE_MEMBER',
  },

  mutations: {
    SET_MEMBER: 'SET_MEMBER',
    SET_MISCELLANEOUS_QUESTIONS: 'SET_MISCELLANEOUS_QUESTIONS',
    SET_PROFILING_QUESTIONS: 'SET_PROFILING_QUESTIONS',
    SET_CLICK_OFFERS: 'SET_CLICK_OFFERS',
    SET_INTERNAL_OFFERS: 'SET_INTERNAL_OFFERS',
    SET_TRACKING_PARAMS: 'SET_TRACKING_PARAMS',
    COMPLETE_APP_OFFER: 'COMPLETE_APP_OFFER',
  }
}