<template>
  <div id="step10">
    <div class="flex flex-wrap justify-center mb-4">
      <img class="w-24 h-24" src="../../assets/kidplaying.png" alt="How many kids do you have?" />
    </div>

    <h1 class="block text-2xl text-center font-extrabold tracking-tight text-gray-900 sm:text-2xl px-10">
      How many kids do you have?
    </h1>
    
    <form>
      <input id="leadid_token" name="universal_leadid" type="hidden" />

      <button @click.prevent="next('none')" type="submit" class="mt-4 w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
        None
      </button>

      <button @click.prevent="next('1-3')" type="submit" class="mt-4 w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
        Between 1-3 kids
      </button>

      <button @click.prevent="next('4-5')" type="submit" class="mt-4 w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
        Between 4-5 kids
      </button>

      <button @click.prevent="next('5+')" type="submit" class="mt-4 w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
        More than 5 kids
      </button>
    </form>
  </div>
</template>

<script>
  import Member from '@/mixins/member'

  export default {
    mixins: [ Member ],
    methods: {
      next(answer) {
        this.setProfilingQuestions({
          total_kids: answer,
        })
        
        if (this.member) {
          this.updateMember({
            id: this.member.id
          })
        }

        this.$emit('next', 10)
      },
    }
  }
</script>