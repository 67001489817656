<template>
  <div class="flex flex-col justify-center w-full h-full text-white bg-white">
    <div class="max-w-5xl mx-auto mt-14 sm:mt-auto px-10 bg-sweeps rounded-2xl">
      <div>
        <div class="flex flex-col px-4">
          <div>
            <img
              class="mx-auto mt-4 w-42"
              src="../assets/sweepstakes/crowd-up-logo-white-1.png"
            />
            <p
              class="
                mt-4
                text-black-500 text-2xl
                md:font-sans
                text-center
                tracking-widest
                font-bold
              "
            >
              Welcome!
            </p>
          </div>
          <div class="w-full">
            <img
              :class="currentClass"
              :src="require(`../assets/sweepstakes/${currentImage}`)"
              alt="iPad Air"
            />
          </div>
          <button
            @click="next"
            type="submit"
            class="
              block
              w-full
              p-2
              mb-10
              text-sm
              font-bold
              tracking-widest
              uppercase
              bg-green-600
              rounded-md
            "
          >
            Continue
          </button>
        </div>
      </div>
    </div>

    <div class="mx-auto mt-4 mb-auto">
      <!-- footer -->
      <div class="text-xs leading-5 text-center text-gray-400">
        <a href="/privacy-policy">Privacy Policy</a>
        <span class="mx-2 font-extrabold">&middot;</span
        ><a href="/terms-and-conditions">Terms & Conditions</a>
        <span class="mx-2 font-extrabold">&middot;</span>
        <a href="/sweepstakes-rules">Sweepstakes Rules</a>
      </div>
    </div>
  </div>
</template>

<script>
import { sweeps } from "../constants/sweeps";
import sweepstakes from "../mixins/sweepstakes";
import constants from "../constants";

export default {
  mixins: [sweepstakes, constants],
  props: {
    sweep: {
      type: String,
      required: true,
    },
  },
  computed: {
    currentPath: function () {
      return sweeps?.[this.sweep].path;
    },
    currentImage: function () {
      return sweeps?.[this.sweep].image;
    },
    currentClass: function () {
      return sweeps?.[this.sweep].gateClass;
    },
  },
  methods: {
    next: function () {
      this.$router.push(`/master-sweeper${this.currentPath}`);
    },
  },
  mounted() {
    if (!sweeps?.[this.sweep]) {
      this.$router.push("/not-found");
    }
  },
};
</script>