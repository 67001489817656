import {
	validatePhoneNumber,
	processValidatedPhoneNumber,
	handleNewUserData
} from '../services/phone'

import YourQuoteGuruPopup from "../components/YourQuoteGuruPopup.vue"
import axios from 'axios'
import { mask } from 'maska'

export default {
	data() {
		return {
			user: {
				'first_name': '',
				'last_name': '',
				'email_address': '',
				'date_of_birth': '',
				'address': '',
				'city': '',
				'state': '',
				'zip_code': '',
				'utm_source': '',
				'utm_medium': '',
				'utm_content': '',
				'utm_term': '',
				'utm_channel': '',
				'gclid': '',
				'fbclid': '',
			},
			phoneNumber: '',
			jornaya: '',
			trustedForm: '',
			ipAddress: '',
			yourQuoteGuruStartUrl: '',
			shouldCollectNewData: false,
			lrnApiValidationFailed: false,
			isValidatingPhoneNumber: false,
		}
	},
	components: {
		YourQuoteGuruPopup
	},
	created() {
		axios.get('https://leadreserve.com/api/ip').then(response => {
			this.ipAddress = response.data
		})

		const interval = setInterval(() =>  {
			if (Object.prototype.hasOwnProperty.call(window, 'trustedForm')) {
				if (typeof window.trustedForm === 'object') {
					let id = window.trustedForm?.id

					if (id !== undefined) {
						this.trustedForm = 'https://cert.trustedform.com/' + id
					}
				}
			}

			if (Object.prototype.hasOwnProperty.call(window, 'LeadiD')) {
				let token = window.LeadiD?.token

				if (token !== undefined) {
					this.jornaya = token
				}
			}

			if (this.trustedForm && this.jornaya) {
				clearInterval(interval)
			}
		}, 100)
	},
	computed: {
		hasClientsideInvalidatedPhoneNumber() {
			return this.unformattedPhone.length !== 10
		},
		hasLrnInvalidatedPhoneNumber() {
			if (this.isValidatingPhoneNumber) {
				return false
			}

			return this.lrnApiValidationFailed
		},
		hasInvalidPhoneNumber() {
			return this.hasClientsideInvalidatedPhoneNumber || this.hasLrnInvalidatedPhoneNumber
		},

		unformattedPhone() {
			return mask(this.phoneNumber, '##########')
		},
	},
	methods: {
		extractQueryParameters() {
			const params = new URLSearchParams(window.location.search)

			this.user.utm_source = params.get('utm_source') ?? ''
			this.user.utm_medium = params.get('utm_medium') ?? ''
			this.user.utm_content = params.get('utm_content') ?? ''
			this.user.utm_term = params.get('utm_term') ?? ''
			this.user.utm_channel = params.get('utm_channel') ?? ''
			this.user.gclid = params.get('gclid') ?? ''
			this.user.fbclid = params.get('fbclid') ?? ''
		},

		processRecord() {
			if (this.shouldCollectNewData) {
				return this.saveNewData()
			}

			this.processPhoneNumber()
		},

		async saveNewData() {
			const { jornaya, trustedForm, unformattedPhone } = this

			const response = await handleNewUserData({
				...this.user,
				jornaya: jornaya,
				trusted_form: trustedForm,
				phone_number: unformattedPhone,
			})

			this.redirectUserToYourQuoteGuruThankYouPage(response?.start_url, response?.member)
		},

		processPhoneNumber() {
			if (this.hasClientsideInvalidatedPhoneNumber === false) {
				this.isValidatingPhoneNumber = true

				validatePhoneNumber(this.unformattedPhone)
					.then((validationResponse) => {
						if (!validationResponse?.success) {
							this.lrnApiValidationFailed = true
							this.isValidatingPhoneNumber = false
							return
						}

						this.handleValidatedPhoneNumber()
					}).catch(() => {
						this.lrnApiValidationFailed = true
						this.isValidatingPhoneNumber = false
					})
			}
		},

		async handleValidatedPhoneNumber() {
			const response = await processValidatedPhoneNumber(
				this.unformattedPhone,
				this.utm_source,
				this.utm_medium,
				this.utm_content,
				this.utm_term,
				this.utm_channel,
				this.gclid,
				this.fbclid,
				this.jornaya,
				this.trustedForm
			)

			if (response?.success === false) {
				this.isValidatingPhoneNumber = false
				this.displayFullDataFormToUser()
			}

			else {
				this.redirectUserToYourQuoteGuruThankYouPage(response?.start_url, response?.member)
			}
		},

		displayFullDataFormToUser() {
			this.shouldCollectNewData = true
		},

		redirectUserToYourQuoteGuruThankYouPage(startUrl, member) {
			window.EF.conversion({
				order_id: this.ipAddress,
				offer_id: window.EF.urlParameter('oid'),
				email: member.email_address,
			})

			window.obApi('track', 'Phone Submit Lead')
			window.fbq('track', 'Lead', { external_id: member.token }, { eventID: member.token })

			this.isValidatingPhoneNumber = false
			this.yourQuoteGuruStartUrl = startUrl
		},

		resetLrnApiValidationFailed() {
			if(this.lrnApiValidationFailed) {
				this.lrnApiValidationFailed = false
			}
		}
	}
}