import { createStore } from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import funnels from '@/store/funnels'
import members from '@/store/members'

export default createStore({
  modules: {
    funnels,
    members,
  },
  plugins: [ createPersistedState() ],
})