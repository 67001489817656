import { mapActions, mapGetters, mapMutations } from 'vuex'
import { types } from '@/store/members/types'

export default {
  data() {
    return {
      leadId: '',
      trustedForm: '',
    }
  },
  computed: {
    ...mapGetters({
      member: types.getters.GET_MEMBER,
      miscellaneousQuestions: types.getters.GET_MISCELLANEOUS_QUESTIONS,
      profilingQuestions: types.getters.GET_PROFILING_QUESTIONS,
      clickOffers: types.getters.GET_CLICK_OFFERS,
      internalOffers: types.getters.GET_INTERNAL_OFFERS,
      appOffers: types.getters.GET_APP_OFFERS,
    }),
  },
  methods: {
    ...mapActions({
      createMember: types.actions.CREATE_MEMBER,
      updateMember: types.actions.UPDATE_MEMBER,
    }),

    ...mapMutations({
      setMiscellaneousQuestions: types.mutations.SET_MISCELLANEOUS_QUESTIONS,
      setProfilingQuestions: types.mutations.SET_PROFILING_QUESTIONS,
      setClickOffers: types.mutations.SET_CLICK_OFFERS,
      setInternalOffers: types.mutations.SET_INTERNAL_OFFERS,
      setTrackingParams: types.mutations.SET_TRACKING_PARAMS,
      completeAppOffer: types.mutations.COMPLETE_APP_OFFER,
    }),
  },
  created() {
    const interval = setInterval(() => {
      if (Object.prototype.hasOwnProperty.call(window, 'trustedForm')) {
        if (typeof window.trustedForm === 'object') {
          let id = window.trustedForm?.id

          if (id !== undefined) {
            this.trustedForm = 'https://cert.trustedform.com/' + id
          }
        }
      }

      if (Object.prototype.hasOwnProperty.call(window, 'LeadiD')) {
        let token = window.LeadiD?.token
        
        if (token !== undefined) {
          this.leadId = token
        }
      }

      if (this.trustedForm && this.leadId) {
        clearInterval(interval)
      }
    }, 50)
  }
}