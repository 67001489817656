<template>
  <div
    class="flex flex-col justify-center w-full min-h-full text-white bg-white"
  >
    <div class="text-gray-400 text-center text-xs mt-10">
      Advertisement
    </div>
    <div
      v-if="yourQuoteGuruStartUrl"
      class="
        fixed
        top-0
        left-0
        z-10
        w-screen
        h-screen
        max-h-screen
        p-3
        overflow-hidden
        sm:p-0
      "
    >
      <div
        class="absolute top-0 left-0 w-full h-full bg-black opacity-60"
      ></div>
      <div class="flex flex-col w-full h-full pb-20">
        <div class="z-20 w-full h-full m-auto my-10 mt-10 opacity-100 sm:w-3/5">
          <div
            class="
              w-full
              h-full
              mx-auto
              overflow-y-auto
              bg-white
              rounded-md
              shadow-lg
            "
          >
            <YourQuoteGuruPopup
              class="h-full"
              :guru-url="yourQuoteGuruStartUrl"
            />
          </div>
        </div>
      </div>
    </div>

    <div class="max-w-5xl mx-10 mt-20 rounded-lg sm:mx-10 lg:mx-auto bg-sweeps">
      <div>
        <img
          class="m-auto mt-8 mb-5 w-42 sm:hidden"
          src="../assets/sweepstakes/crowd-up-logo-white-1.png"
        />
      </div>

      <div class="py-5 sm:flex sm:p-14">
        <!-- left -->
        <div class="flex flex-col px-10 sm:px-5">
          <!-- green box -->
          <div
            class="
              inline-block
              w-auto
              col-span-2
              p-4
              px-8
              m-auto
              my-5
              mt-0
              text-center
              bg-green-500
              rounded-md
              sm:mt-5 sm:order-2 sm:w-60
            "
          >
            <div class="flex flex-wrap">
              <div class="flex-grow text-3xl font-bold">
                889 <span class="block text-sm font-light">entries</span>
              </div>
              <div class="mx-5 border-r-2"></div>
              <div class="flex-grow text-3xl font-bold">
                12 <span class="block text-sm font-light">days left</span>
              </div>
            </div>
          </div>

          <!-- iPad  -->
          <div class="w-full mt-3">
            <img
              :class="currentClass"
              :src="require(`../assets/sweepstakes/${currentImage}`)"
              alt="iPad Air"
            />
          </div>
        </div>

        <!-- right -->
        <div class="px-12 sm:px-6 lg:px-12">
          <!-- CrowdUp logo - wider screen -->
          <img
            class="hidden w-40 sm:block sm:mb-9"
            src="../assets/sweepstakes/crowd-up-logo-white-1.png"
            alt="CrowdUp logo"
          />

          <!-- message and form -->
          <div class="text-left">
            <div class="my-4 mt-12 text-4xl font-bold text-center sm:text-left">
              Enter to <span class="font-extrabold text-red-500">WIN</span>
              {{ currentArticle }}
              <span class="font-extrabold text-red-500">{{ currentName }}</span>
              Now!
            </div>

            <form @submit.prevent="processRecord">
              <template v-if="shouldCollectNewData">
                <div
                  class="grid grid-cols-2 my-6 gap-y-3 gap-x-4 sm:grid-cols-6"
                >
                  <div class="col-span-2 sm:col-span-3">
                    <label for="first-name" class="block text-sm font-medium"
                      >First name</label
                    >

                    <input
                      required
                      id="first-name"
                      v-model="user.first_name"
                      type="text"
                      autocomplete="given-name"
                      class="
                        block
                        w-full
                        mt-1
                        text-black
                        border-green-300
                        rounded-md
                        shadow-sm
                        focus:ring-green-500 focus:border-green-500
                        sm:text-sm
                      "
                    />
                  </div>

                  <div class="col-span-2 sm:col-span-3">
                    <label for="last-name" class="block text-sm font-medium"
                      >Last name</label
                    >

                    <input
                      required
                      id="last-name"
                      v-model="user.last_name"
                      type="text"
                      autocomplete="family-name"
                      class="
                        block
                        w-full
                        mt-1
                        text-black
                        border-green-300
                        rounded-md
                        shadow-sm
                        focus:ring-green-500 focus:border-green-500
                        sm:text-sm
                      "
                    />
                  </div>

                  <div class="col-span-2 sm:col-span-3">
                    <label for="email-address" class="block text-sm font-medium"
                      >Email address</label
                    >

                    <input
                      required
                      id="email-address"
                      v-model="user.email_address"
                      type="text"
                      autocomplete="email"
                      class="
                        block
                        w-full
                        mt-1
                        text-black
                        border-green-300
                        rounded-md
                        shadow-sm
                        focus:ring-green-500 focus:border-green-500
                        sm:text-sm
                      "
                    />
                  </div>

                  <div class="col-span-2 sm:col-span-3">
                    <label for="birthdate" class="block text-sm font-medium"
                      >Date of Birth</label
                    >
                    <input
                      required
                      id="birthdate"
                      v-model="user.date_of_birth"
                      type="text"
                      v-maska="'##/##/####'"
                      class="
                        block
                        w-full
                        mt-1
                        text-black
                        border-green-300
                        rounded-md
                        shadow-sm
                        focus:ring-green-500 focus:border-green-500
                        sm:text-sm
                      "
                    />
                  </div>

                  <div class="col-span-2 sm:col-span-6">
                    <label for="address" class="block text-sm font-medium"
                      >Address</label
                    >
                    <input
                      required
                      id="address"
                      v-model="user.address"
                      type="text"
                      class="
                        block
                        w-full
                        mt-1
                        text-black
                        border-green-300
                        rounded-md
                        shadow-sm
                        focus:ring-green-500 focus:border-green-500
                        sm:text-sm
                      "
                    />
                  </div>

                  <div class="col-span-2">
                    <label for="city" class="block text-sm font-medium"
                      >City</label
                    >
                    <input
                      required
                      id="city"
                      v-model="user.city"
                      type="text"
                      class="
                        block
                        w-full
                        mt-1
                        text-black
                        border-green-300
                        rounded-md
                        shadow-sm
                        focus:ring-green-500 focus:border-green-500
                        sm:text-sm
                      "
                    />
                  </div>

                  <div class="col-span-1 sm:col-span-2">
                    <label for="state" class="block text-sm font-medium"
                      >State</label
                    >
                    <select
                      required
                      id="state"
                      v-model="user.state"
                      class="
                        block
                        w-full
                        px-3
                        py-2
                        mt-1
                        text-black
                        bg-white
                        border border-green-300
                        rounded-md
                        shadow-sm
                        focus:outline-none
                        focus:ring-green-500
                        focus:border-green-500
                        sm:text-sm
                      "
                    >
                      <option v-for="(state, index) in states" :key="index">
                        {{ state }}
                      </option>
                    </select>
                  </div>

                  <div class="col-span-1 sm:col-span-2">
                    <label for="zip-code" class="block text-sm font-medium"
                      >Zipcode</label
                    >
                    <input
                      required
                      id="zip-code"
                      v-model="user.zip_code"
                      type="text"
                      v-maska="'#####'"
                      autocomplete="postal-code"
                      class="
                        block
                        w-full
                        mt-1
                        text-black
                        border-green-300
                        rounded-md
                        shadow-sm
                        focus:ring-green-500 focus:border-green-500
                        sm:text-sm
                        n-300
                      "
                    />
                  </div>
                </div>
              </template>

              <template v-else>
                <div class="relative mb-6">
                  <label for="phone" class="block text-sm font-medium">
                    Phone
                  </label>

                  <input
                    @keypress="resetLrnApiValidationFailed"
                    type="tel"
                    v-maska="'(###) ###-####'"
                    v-model="phoneNumber"
                    class="
                      block
                      w-full
                      mt-1
                      text-black
                      border-green-300
                      rounded-md
                      shadow-sm
                      focus:ring-green-500 focus:border-green-500
                      sm:text-sm
                    "
                  />
                  <p
                    v-if="lrnApiValidationFailed"
                    class="text-red-500 mt-2 font-medium text-sm"
                  >
                    Please add a valid phone number
                  </p>

                  <p v-else class="text-sm italic font-medium mt-1">
                    We need this to contact you if you win!
                  </p>
                </div>
              </template>

              <button
                type="submit"
                class="
                  block
                  w-full
                  p-2
                  mb-4
                  text-sm
                  font-bold
                  tracking-widest
                  uppercase
                  bg-green-600
                  rounded-md
                  disabled:bg-green-300
                "
                :disabled="isValidatingPhoneNumber || hasInvalidPhoneNumber"
              >
                Submit
              </button>

              <input id="leadid_token" name="universal_leadid" type="hidden" />

              <label class="my-6 text-xs text-left text-gray-100">
                <input type="hidden" id="leadid_tcpa_disclosure" />
                By submitting this form, I expressly consent to be contacted by a <a href="https://www.acquirecrowd.com/partners/" target="_blank" style="text-decoration: underline">partner</a> in the AcquireCrowd Network with materials for services via direct or electronic mail, phone calls to the phone number provided, text/SMS messages, via automatic/automated dialing system(s), and pre-recorded messages. Consent is not a condition of purchase and may be revoked at any time.
              </label>
            </form>
          </div>
        </div>
      </div>
    </div>

    <div class="px-10 pt-10 pb-5">
      <!-- footer -->
      <div class="text-xs leading-5 text-center text-gray-400">
        <a href="/privacy-policy">Privacy Policy</a>
        <span class="mx-2 font-extrabold">&middot;</span
        ><a href="/terms-and-conditions">Terms & Conditions</a>
        <span class="mx-2 font-extrabold">&middot;</span>
        <a href="/sweepstakes-rules">Sweepstakes Rules</a>
      </div>
    </div>

    <div class="px-10 pt-5 pb-10 text-xs leading-1 text-center text-gray-400 max-w-3xl m-auto">
      Coupons, sweepstakes, samples, and other offers (collectively "Offers") change often. We cannot guarantee that you will receive any of the Offers that you request, as Offers are subject to availability and are often made by other entities. Offers distributed by Crowdup are limited in quantity, and The Crowdup Info reserves the right to substitute sample product based on availability. Offers that are not distributed by The Crowdup are monitored by other entities, and we are not responsible if such Offers are no longer available, change, if you do not receive the Offers, if you do not qualify for the Offers, or if any other issues arise. The third-party product names, logos, brands, and trademarks shown on this website are the property of their respective owners. These companies are not affiliated with Crowdup. These third parties do not sponsor or endorse this website, it's content or services.
    </div>
  </div>
</template>

<script>
import { sweeps } from "../constants/sweeps";
import sweepstakes from "../mixins/sweepstakes";
import constants from "../constants";

export default {
  mixins: [sweepstakes, constants],
  props: {
    sweep: {
      type: String,
      required: true,
    },
  },
  computed: {
    currentName: function () {
      return sweeps?.[this.sweep].name;
    },
    currentImage: function () {
      return sweeps?.[this.sweep].image;
    },
    currentClass: function () {
      return sweeps?.[this.sweep].sweepClass;
    },
    currentArticle: function () {
      return sweeps?.[this.sweep].article;
    },
  },
  mounted() {
    if (!sweeps?.[this.sweep]) {
      this.$router.push("/not-found");
    }
  },
};
</script>