import _ from 'lodash'
import { createApp } from 'vue'
import VueGtag from 'vue-gtag'
import Maska from 'maska'
import moment from 'moment'
import App from './App'
import router from './router'
import store from './store'
import './index.css'

const app = createApp(App)
  
app.use(store)
   .use(router)
   .use(VueGtag, {
      config: {
         id: 'G-RMNHNGPVE4'
      }
   })
   .use(Maska)
   .mount('#app')

app.config.globalProperties.$moment = moment
app.config.globalProperties.$_ = _