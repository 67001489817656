<template>
  <div class="space-y-6">
    <div class="my-3 bg-white sm:rounded-lg">
      <form class="space-y-6" action="#" method="POST">
        <div class="grid grid-cols-3 gap-6">
          <div class="col-span-3">
            <label for="name" class="block text-sm font-medium text-gray-700">
              Name
            </label>
            <div class="flex mt-1 rounded-md shadow-sm">
              <input
                type="text"
                name="name"
                id="name"
                class="flex-1 block w-full border-gray-300 rounded-md focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              />
            </div>
          </div>
        </div>

        <div class="grid grid-cols-3 gap-6">
          <div class="col-span-3">
            <label for="email" class="block text-sm font-medium text-gray-700">
              Email
            </label>
            <div class="flex mt-1 rounded-md shadow-sm">
              <input
                type="text"
                name="email"
                id="email"
                class="flex-1 block w-full border-gray-300 rounded-md focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              />
            </div>
          </div>
        </div>

        <div>
          <label for="message" class="block text-sm font-medium text-gray-700">
            Message
          </label>
          <div class="mt-1">
            <textarea
              id="message"
              name="message"
              rows="3"
              class="block w-full border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            />
          </div>
        </div>
      </form>
    </div>
  </div>
</template>
