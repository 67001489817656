<template>
  <div
    class="flex flex-col items-center min-h-screen py-10 bg-gray-100 sm:py-8"
  >
    <div
      class="
        w-full
        p-6
        mt-6
        overflow-hidden
        prose
        bg-white
        shadow-md
        sm:max-w-2xl sm:rounded-lg
      "
    >
      <h1>General Terms and Conditions for Sweepstakes Official Rules</h1>
      <p>
        <strong
          >NO PURCHASE OR PAYMENT OF ANY KIND IS NECESSARY TO ENTER. A PURCHASE
          OR PAYMENT OF ANY KIND WILL NOT IMPROVE CHANCES OF WINNING. VOID WHERE
          PROHIBITED. OPEN ONLY TO LEGAL RESIDENTS OF THE 50 UNITED STATES. ODDS
          OF WINNING DEPEND ON THE NUMBER OF ELIGIBLE ENTRIES.</strong
        >
      </p>
      <p>By entering a sweepstakes (each, a "<strong>Sweepstakes</strong>"):</p>
      <ul>
        <li>
          you will be bound by and comply with these Official Rules for the
          Sweepstakes ("Official Rules"), which include the terms for the
          applicable Sweepstakes ("Sweepstakes Terms") as well as these General
          Terms and Conditions, and which together form a legally binding
          agreement between you and the Sponsor identified in the applicable
          Sweepstakes Terms;
        </li>
        <li>
          you represent that you satisfy all eligibility requirements; and
        </li>
        <li>
          you will comply with all applicable laws, rules, or regulations
          (collectively, "<strong>Applicable Laws</strong>").
        </li>
      </ul>
      <p>
        If you do not agree to these Official Rules, do not enter the
        Sweepstakes.
      </p>
      <p>
        <strong>Sweepstakes Terms</strong>. The Sweepstakes Terms include
        various Sweepstakes-specific terms, including the identity of the
        Sponsor(s) and any administrator, information about the prize(s) and the
        approximate retail value of prize(s), how to enter, when and how winners
        will be selected, odds of winning, and the Sweepstakes Period (the start
        and end dates/times for the Sweepstakes). In the event of any conflict
        between the applicable Sweepstakes Terms and these General Terms and
        Conditions, the General Terms and Conditions will control.
      </p>
      <p>
        <strong>Eligibility</strong>. Open to individual legal residents of the
        50 United States. Employees, representatives and agents of Sponsor, its
        affiliates (including Tender Corporation d/b/a Crowdup
        ("<strong>Crowdup</strong>"), if Crowdup is not itself the Sponsor), and
        any third-party administrator or promotional partners, and their
        immediate family members and anyone domiciled with any of the above are
        not eligible to participate in the Sweepstakes.
      </p>
      <p>
        All entries must be received during the Sweepstakes Period in order to
        be eligible. Limit one entry per person per Sweepstakes and entrant must
        use own email address. For avoidance of doubt, you may not enter
        multiple times using multiple accounts. All Sweepstakes entries become
        the property of Sponsor and will not be acknowledged or returned.
      </p>
      <p>
        <strong
          >Prize(s); Winner Selection &amp; Notification of Winners;
          Conditions</strong
        >. Subject to these General Terms and Conditions, the Sponsor will award
        a prize to one or more individuals (as specified in the applicable
        Sweepstakes Terms) who will be selected at random from among all
        eligible entries received. If you are selected to be a winner, an e-mail
        notification or social media message will be sent to you (depending on
        the medium in which the Sweepstakes is taking place and/or how you
        entered). Each person selected to win must respond within seven (7) days
        of the notification being sent to them. Any Canadian resident selected
        to win a prize must correctly answer a time-limited math skill-testing
        question, in addition to otherwise satisfying all sweepstakes
        requirements, in order to be confirmed a prize winner. Each winner must
        provide any information required by Sponsor for delivery of any prize,
        including, if applicable, your shipping address.
      </p>
      <p>
        Subject to these Official Rules, prize(s) will be fulfilled by the
        Sponsor. Each prize will be awarded only after (i) Sponsor's receipt of
        the selected winner's response and any required affidavit of
        eligibility, declaration of compliance, release and/or other
        documentation; (2) proper verification of the selected winner's
        identity; (3) for Canadian resident(s), the correct answer to the math
        skill-testing question, all subject to selected winner's compliance with
        the Official Rules. Return of the prize notification as "undeliverable"
        or any failure to respond or to return any required affidavit or other
        documentation in the time period provided above, or any other failure to
        comply with these Official Rules, will result in disqualification of the
        selected winner and forfeiture of the prize. Sponsor is not required to
        award elsewhere any prizes forfeited by the chosen winner. Sponsor is
        not responsible for any late, lost, stolen, damaged, delayed, or
        undelivered prizes.
      </p>
      <p>
        Each winner will be solely responsible for paying any taxes and any
        other expenses incurred by the winner in connection with receiving the
        prize or participating in the Sweepstakes, including any income taxes,
        sales or use taxes, value added taxes, goods and services taxes, or
        harmonized sales taxes. Throughout the term of a Sweepstakes, and as a
        condition to receiving any prize, you will provide Sponsor with an
        affidavit of eligibility, declaration of compliance, release, and any
        other forms, documents, or certifications, including any that may be
        required for Sponsor (or its affiliates, any third-party administrator,
        or promotional partners) to satisfy any information reporting or
        withholding tax obligations with respect to any payments related to a
        Sweepstakes.
      </p>
      <p>
        Each prize will be awarded "as is" and without warranty of any kind,
        express or implied (including any implied warranty of merchantability or
        fitness for a particular purpose). If you win a prize, you may not
        transfer, assign, or redeem the prize for cash. The Sponsor may
        substitute a prize with a prize of similar or greater value.
      </p>
      <p>
        <strong>Other Sweepstakes</strong>. By entering a Sweepstakes, you will
        not be automatically entered into or eligible to participate in or
        receive any prizes awarded in any other Sweepstakes. In order to enter
        multiple Sweepstakes, you must enter each Sweepstakes separately in
        accordance with the respective Sweepstakes' entry and eligibility
        requirements.
      </p>
      <p>
        <strong>Licenses to User-Generated Content; Rights of Publicity</strong
        >. By entering a Sweepstakes, you grant an irrevocable, perpetual,
        worldwide, non-exclusive license to Sponsor and its affiliates
        (including Crowdup), to use, reproduce, modify, distribute, display,
        publish and create derivative works of any content that you provide,
        make available, or upload to a social media platform in connection with
        a Sweepstakes (collectively, "<strong>User-Generated Content</strong>")
        in whole or in part in all media now known or hereafter devised,
        throughout the universe, in any way Sponsor or its affiliates see fit.
        You consent to Sponsor and its affiliates doing or omitting to do any
        act that would otherwise infringe your "moral rights" in any such
        User-Generated Content and you waive in favor of Sponsors and its
        affiliates any moral rights you may have in such content. Display or
        publication of any User-Generated Content on Sponsor's or its
        affiliates' website or social media properties does not indicate that
        you will be selected as a winner. Sponsor and its affiliates will not
        pay any additional consideration, notification, or permission in
        connection with any use of any User-Generated Content. You represent and
        warrant that (i) you own all rights, including all intellectual property
        rights, in and to all of your User-Generated Content; (ii) you are the
        original author of all of your User-Generated Content; and (iii) you
        have all rights necessary to grant the rights you are granting herein,
        and such grants will not violate any agreements between you and any
        third party or violate any third party's privacy rights.
      </p>
      <p>
        You agree that, by entering a Sweepstakes, Sponsor and its affiliates
        (including Crowdup, if it is not the Sponsor itself) may use, display,
        publish, or broadcast, the winner's name, image, or likeness in any
        manner and in any medium for its promotional and advertising purposes
        without additional compensation, notification, or permission, including
        posting in a winner's list, except where prohibited by law.
      </p>
      <p>
        Upon Sponsor or its affiliate's request, you will sign any necessary
        documentation that may be required for Sponsor or its affiliate to make
        use of the rights granted herein.
      </p>
      <p>
        <strong>Termination of Sweepstakes; Changes to Official Rules</strong>.
        Sponsor may modify or cancel a Sweepstakes or these Official Rules at
        any time without prior notice for any reason whatsoever, except this
        right is subject to the approval of the R&eacute;gie des alcools, des
        courses et des jeux with respect to the province of Quebec. Without
        limiting the foregoing, (a) if for any reason a Sweepstakes is not
        capable of running as originally planned, including should any virus,
        worm, bug, technical failures, unauthorized human intervention, or other
        causes corrupt or affect the administration, security, fairness, or
        proper conduct of a Sweepstakes, Sponsor may terminate the Sweepstakes;
        (b) Sponsor will not be liable for failure, delay, or termination in a
        Sweepstakes because of causes beyond its reasonable control, including
        without limitation acts of God, terrorism, war, riots, fire, earthquake,
        flood, or degradation, or failure of third party networks or
        communications infrastructure; and (c) Sponsor may modify a Sweepstakes
        Period depending on the available supply of prizes.
      </p>
      <p>
        <strong>Release</strong>. Sponsor, its promotional partners, any
        third-party administrator, and any other entities involved in any aspect
        of the Sweepstakes, and their affiliates (including Crowdup it is not
        the Sponsor itself), officers, directors, employees, representatives,
        and agents (collectively, the "<strong>Released Parties</strong>") are
        not responsible for and do not assume any liability for, and you agree
        to release and will hold them harmless from and against any and all
        liability, loss, injury, or damage to property or person, including
        death, rights, claims, and actions, and reasonable attorney's fees and
        court costs, of any kind (collectively, "<strong>Losses</strong>")
        arising in whole or in part, directly or indirectly, from: (a) the
        administration of a Sweepstakes, including any modification or
        termination of the Sweepstakes, the selection or confirmation of winners
        or awarding of prizes, and any errors, omissions, or inaccuracies in
        connection with the foregoing or in any Sweepstakes-related materials;
        (b) the acceptance, possession, use, or misuse of a prize (or any
        product purchased with a prize) or participation in a Sweepstakes; (c)
        any lost, misdirected, late, or incomplete entries or for inaccurate
        entry information; (d) any operation or transmission error, failure of
        electronic communications, theft, unauthorized access to, or alteration
        of, entries; (e) any damage to any computer or device resulting from
        accessing or downloading Sweepstakes materials or otherwise
        participating in a Sweepstakes; (f) the security or privacy of
        information transmitted via computer networks, including any breaches of
        privacy due to interference by a third party, including third-party
        computer "hackers" or otherwise; or (g) any failure by you to make any
        disclosures in any social media post or activity in connection with the
        Sweepstakes as required by these Official Rules or Applicable Law.
        Sponsor may disqualify any individual found to be: (x) tampering with a
        Sweepstakes; (y) violating these Official Rules; or (z) acting in an
        unsportsmanlike or disruptive manner or with intent to threaten or
        harass any person, and each of the foregoing will be deemed a breach of
        these Official Rules. Incomplete, robotic, automatic, programmed, or
        similar entries will be disqualified. The authorized subscriber of the
        account used to enter a Sweepstakes at the actual time of entry will be
        deemed to be the participant and must comply with these Official Rules
        in the event of a dispute as to entries submitted by multiple users
        having the same e-mail or social media account.
      </p>
      <p>
        <strong>Limitation of Liability</strong>. Without limiting the foregoing
        or any other provision herein, the Released Parties' total liability for
        any and all claims, judgements, and awards against the Released Parties
        shall not exceed the total out-of-pocket expenses incurred by you to
        enter the applicable Sweepstakes. In no event shall the Released Parties
        be liable for any attorneys' fees, punitive, indirect, incidental,
        special, and consequential damages.
      </p>
      <p>
        <strong>No Facebook or Instagram Endorsement; Release</strong>. A
        Sweepstakes is in no way sponsored by, endorsed by, administered by, or
        associated with Facebook, Inc. ("<strong>Facebook</strong>") or
        Instagram, LLC ("<strong>Instagram</strong>"). You understand that you
        are providing information to Sponsor and not to Facebook and Instagram.
        However, you understand that by using and interacting with facebook.com,
        the Facebook mobile app, Instagram.com or the Instagram mobile app, you
        are subject to the terms, conditions, and policies that govern the use
        of those websites and mobile apps. You should therefore review the
        applicable terms and policies, including privacy and data gathering
        practices, before using or interacting with them. You may not
        inaccurately tag content on Facebook or Instagram in connection with the
        Sweepstakes. By entering a Sweepstakes, you agree to and do hereby
        release, discharge, and hold harmless Facebook and Instagram and their
        respective affiliates, employees, officers, directors, and
        representatives from any Losses arising out of (a) participation in a
        Sweepstakes or any Sweepstakes-related activities, or (b) the
        acceptance, use, misuse, or possession of any prize awarded hereunder or
        any product purchased with any prize awarded hereunder.
      </p>
      <p>
        <strong>Sponsor's Decisions are Final</strong>. You agree that Sponsor's
        decisions related to any Sweepstakes and any distribution of prizes are
        final.
      </p>
      <p>
        <strong>WAIVER OF JURY TRIAL AND CLASS ACTIONS</strong>. EACH PARTY
        HERETO IRREVOCABLY AND UNCONDITIONALLY WAIVES ALL RIGHT TO TRIAL BY JURY
        IN OR TO HAVE A JURY PARTICIPATE IN RESOLVING ANY DISPUTE, ACTION,
        PROCEEDING, OR COUNTERCLAIM, WHETHER SOUNDING IN CONTRACT, TORT, OR
        OTHERWISE, ARISING OUT OF OR RELATING TO OR IN CONNECTION WITH THESE
        OFFICIAL RULES, THE SWEEPSTAKES, OR ANY PRIZE (OR ANY PRODUCT PURCHASED
        WITH A PRIZE). ALL CAUSES OF ACTION ARISING OUT OF OR CONNECTED WITH
        THIS SWEEPSTAKES, OR ANY PRIZES AWARDED (OR ANY PRODUCTS PURCHASED WITH
        A PRIZE), SHALL BE RESOLVED INDIVIDUALLY, WITHOUT RESORT TO ANY FORM
        CLASS ACTION.
      </p>
      <p>
        <strong>Choice of Law; Jurisdiction; Equitable Relief</strong>. This
        sweepstakes is void wherever prohibited or restricted by Applicable Law
        and is subject to all Applicable Laws. Each Sweepstakes is governed by
        the laws of New Hampshire. You hereby consent to the exclusive
        jurisdiction and venue of the federal and state courts of the state of
        New Hampshire for resolution of all claims, differences, and disputes
        the parties may have regarding this Agreement. Notwithstanding anything
        to the contrary herein, you acknowledge and agree that a breach by you
        of these Official Rules would cause Sponsor irreparable harm for which
        monetary damages would not be an adequate remedy, and in the event of
        such breach or threatened breach, Sponsor will be entitled to injunctive
        or equitable relief. In addition, Sponsor's affiliates will have the
        right to seek any remedies available to Sponsor directly on behalf of
        Sponsor.
      </p>
      <p>
        <strong
          >Privacy Notice: All information submitted in connection with a
          Sweepstakes will be used and treated in accordance with these Official
          Rules and </strong
        ><a href="/privacy-policy" target="_blank" rel="noopener"
          ><strong>Crowdup Privacy Policy</strong></a
        ><strong>.</strong>
      </p>
      <p>
        You agree that personal information shared in connection with a
        Sweepstakes may be (i) processed, shared, and otherwise used for the
        purposes of administering and within the context of a Sweepstakes; and
        (ii) used by the Sponsor to verify your identity, postal address, and
        telephone number or to otherwise verify your eligibility to participate
        in a Sweepstakes. You acknowledge that Sponsor may be required to
        disclose the winner's address to regulatory authorities, and you agree
        to any such disclosure.
      </p>
      <p>
        <strong>Additional Terms</strong>. If any term or provision of these
        Official Rules is invalid, illegal, or unenforceable in any
        jurisdiction, such invalidity, illegality, or unenforceability shall not
        affect any other term or provision of these Official Rules or invalidate
        or render unenforceable such term or provision in any other
        jurisdiction. If any term of these Official Rules is held to be
        excessively broad or invalid, illegal, or unenforceable in any
        jurisdiction, it shall be reformed and construed by limiting and
        reducing it so as to be enforceable to the maximum extent permitted by
        Applicable Law in conformance with its original intent.
      </p>
    </div>
  </div>
</template>