import { types } from '@/store/funnels/types'

const state = () => ({
  currentCoins: 0,
  currentIndex: 0,
  steps: [ 
    { component: 'Step1',  permalink: 'step-1',  complete: false, spotsRemaining: 8, notification: false }, 
    { component: 'Step2',  permalink: 'step-2',  complete: false, spotsRemaining: 8, notification: false }, 
    { component: 'Step3',  permalink: 'step-3',  complete: false, spotsRemaining: 8, notification: false }, 
    { component: 'Step4',  permalink: 'step-4',  complete: false, spotsRemaining: 8, notification: false }, 
    { component: 'Step5',  permalink: 'step-5',  complete: false, spotsRemaining: 8, notification: false }, 
    { component: 'Step6',  permalink: 'step-6',  complete: false, spotsRemaining: 8, notification: 'Sophie from New York just earned $100!' }, 
    { component: 'Step7',  permalink: 'step-7',  complete: false, spotsRemaining: 6, notification: false }, 
    { component: 'Step8',  permalink: 'step-8',  complete: false, spotsRemaining: 6, notification: false }, 
    { component: 'Step9',  permalink: 'step-9',  complete: false, spotsRemaining: 6, notification: false }, 
    { component: 'Step10', permalink: 'step-10', complete: false, spotsRemaining: 6, notification: false }, 
    { component: 'Step11', permalink: 'step-11', complete: false, spotsRemaining: 6, notification: 'Marley from Illinois just earned $100!' }, 
    { component: 'Step12', permalink: 'step-12', complete: false, spotsRemaining: 6, notification: false }, 
    { component: 'Step13', permalink: 'step-13', complete: false, spotsRemaining: 4, notification: false }, 
    { component: 'Step14', permalink: 'step-14', complete: false, spotsRemaining: 4, notification: false }, 
    { component: 'Step15', permalink: 'step-15', complete: false, spotsRemaining: 4, notification: 'Debra from Alabama just earned $100!' }, 
    { component: 'Step16', permalink: 'step-16', complete: false, spotsRemaining: 4, notification: false }, 
    { component: 'Step17', permalink: 'step-17', complete: false, spotsRemaining: 4, notification: false }, 
    { component: 'Step18', permalink: 'step-18', complete: false, spotsRemaining: 4, notification: false }, 
    { component: 'Step19', permalink: 'step-19', complete: false, spotsRemaining: 4, notification: false }, 
    { component: 'Step20', permalink: 'step-20', complete: false, spotsRemaining: 2, notification: 'Douglas from Oregon just earned $100!' }, 
    // { component: 'Step21', permalink: 'step-21', complete: false, spotsRemaining: 6, notification: false }, 
    // { component: 'Step22', permalink: 'step-22', complete: false, spotsRemaining: 6, notification: false }, 
    { component: 'Step23', permalink: 'step-23', complete: false, spotsRemaining: 6, notification: false }, 
    { component: 'Step24', permalink: 'step-24', complete: false, spotsRemaining: 2, notification: false }, 
  ]
})

const getters = {
  [types.getters.GET_STEPS]: (state) => state.steps,
  [types.getters.GET_CURRENT_COINS]: (state) => state.currentCoins,
  [types.getters.GET_CURRENT_INDEX]: (state) => state.currentIndex,
  [types.getters.GET_CURRENT_STEP]: (state) => state.steps[state.currentIndex],
  [types.getters.GET_CURRENT_COMPONENT]: (state) => state.steps[state.currentIndex].component,
}

const mutations = {
  [types.mutations.SET_CURRENT_INDEX]: (state, index) => {
    state.currentIndex = index
  },
  [types.mutations.INCREMENT_CURRENT_COINS]: (state, coins) => {
    state.currentCoins = state.currentCoins + coins
  },
  [types.mutations.INCREMENT_CURRENT_INDEX]: (state) => {
    state.currentIndex = state.currentIndex + 1
  },
  [types.mutations.DECREMENT_CURRENT_INDEX]: (state) => {
    state.currentIndex = state.currentIndex - 1
  },
  [types.mutations.COMPLETE_STEP]: (state, index) => {
    state.steps[index].complete = true
  }
}

export default {
  state,
  getters,
  mutations,
}